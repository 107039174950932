/* You can add global styles to this file, and also import other style files */

.modal-content {
    // background-color: transparent !important;
    // border: 0px !important;
}

// @import "./assets/scss/responsive.scss";
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
// @import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

/* You can add global styles to this file, and also import other style files */

html {
    margin: 0;
    padding: 0;
}

body {
    // background-color: #f0f5fc !important;
    background-color: #fff !important;
    margin: 0;
    padding: 0;
}

aw-wizard-navigation-bar ul.steps-indicator li a .label {
    color: none;
    line-height: 14px;
    font-size: 13px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    transition: 0.25s;
    /* margin-top:10px;
 margin-bottom: 10px; */
}

.select-ass .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    border-bottom: 1px solid #8080808a;
    margin: 10px 0px;
}

.select-rappel .ng-select-container .ng-value-container .ng-input>input {
    padding: 15px 31px !important;
}

.select-rappel {
    width: 78%;
    border: 1px solid black;
}

.select-rappel.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    color: #000000d1;
    padding: 15px;
}

.text-red.min-max {
    text-align: left;
    margin-top: -12px;
}

.no-margin {
    margin: 0 !important;
}

.box-shadow {
    box-shadow: 0 0 7px 1px #6f6f6f2e;
    border-radius: 5px;
    min-height: 80px;
    padding: 10px;
    cursor: pointer;
}

// .amsa-form-page{
//   .main-title{
//     color: #2222b5;
//     margin-bottom: 2em;
//     text-align: center;
//   }
// }
.text-red {
    color: red;
}

.banner-content-2 .title {
    font-size: 76px;
    line-height: 86px;
    margin-bottom: 15px;
    text-align: left !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700 !important;
    margin: 0;
    line-height: 1.2;
    color: #3b368c;
    font-family: "Josefin Sans", sans-serif;
}

.stepper-container {
    padding: 0 !important;
    background: none !important;
}

.banner-bg-2 {
    right: 0;
    bottom: 0;
    height: 100%;
    width: 63%;
    background-position: left bottom;
    position: absolute;
    top: -10em;
}

.top-left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 120%;
    top: -10em;
    img {
        width: 100%;
    }
}

#home.header {
    padding: 8em 0 5em;
}

.header-sub-text p {
    font-size: 24px !important;
    line-height: 34px;
    margin: 0;
    margin-bottom: 38px;
    color: #3b368c;
}

.banner-button-group {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.banner-button-group .button-4 {
    padding: 15px 37px;
    font-size: 16px;
}

.button-4 {
    text-transform: uppercase;
    font-weight: 600;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    color: #fff !important;
    padding: 15px 45px;
    background: -ms-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
    background: -moz-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
    background: -webkit-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    transition: all ease .3s;
    margin: 10px !important;
    cursor: pointer;
}

.button-4:hover {
    color: #fff;
    box-shadow: 0 17px 24px 0 rgba(18, 83, 252, .51);
}

.banner-button-group .button-4.active {
    background: 0 0;
    color: #3b368c !important;
    border: 1px solid #dcdcfa;
}

.fixed-header-top header.nav-abs {
    background: #ffffff!important;
    // top: 5px !important;
}

.service-item {
    padding: 50px 20px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 20px;
    box-shadow: 1.95px 15.881px 30px 0 rgba(47, 105, 252, .1);
    background-color: #fff;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    transition: all ease .3s;
    &:hover {
        box-shadow: 1.95px 15.881px 30px 0 rgba(47, 105, 252, .31);
    }
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator {
    padding: 0 !important;
}

.btn-next {
    background: #02236b !important;
}

.btn-prev {
    background-color: #ce0b0b !important;
}

// .form-container .form-control {
//     border-radius: 5px !important;
//     border: 2px solid #fff;
//     box-shadow: 0px 0px 5px #dad6d6b3 !important;
//     height: 42px;
// }
::-webkit-scrollbar {
    // width: 5px;
    // background: #204481 !important;
}


/* Track */

::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    // border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    // background: #5aa0fc;
}


/* Handle */

::-webkit-scrollbar-thumb {
    // background: #336ccf !important;
    // border-radius: 10px;
}

aw-wizard {
    background: white !important;
    box-shadow: 0 3px 9px 0 rgba(32, 33, 36, .28) !important;
    border: {
        bottom: 2px solid var(--ColorPrimary) !important;
        left: 2px solid var(--ColorPrimary) !important;
        right: 2px solid var(--ColorPrimary) !important;
        radius: 10px 10px 10px 10px !important;
    }
    ;
}

.stepper-container {
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
    // margin-bottom: 100px !important;
    background: white;
}

// .wizard-steps{
// max-height: 900px;
// overflow-y:scroll;
// }
.horizontal.large-empty ul.steps-indicator {
    padding: 75px 0 5px 0 !important;
    // background:hsl(210deg 100% 9%) !important;
    // background: hsl(210deg 100% 18%) !important;
    background: var(--ColorPrimary) !important;
    border-radius: 10px 10px 0px 0px !important;
}

.horizontal.large-empty ul.steps-indicator li:not(:last-child):after {
    background-color: white !important;
    position: absolute;
}

.horizontal.large-empty ul.steps-indicator li .step-indicator {
    border: 2px solid !important;
    color: white !important;
}

.horizontal.large-empty ul.steps-indicator li.optional .step-indicator {
    border: 2px solid #ffffffad !important;
    color: #ffffffad !important;
}

.horizontal.large-empty ul.steps-indicator li.done .step-indicator {
    border: 2px solid #ffffffad !important;
    color: #ffffffad !important;
}

.horizontal.large-empty ul.steps-indicator li.current .step-indicator {
    border: 2px solid var(--ColorDefault) !important;
    color: #FFFFFF !important
}

.horizontal.large-empty ul.steps-indicator li.editing .step-indicator {
    border: 2px solid var(--ColorDefault) !important;
    color: #FFFFFF !important;
}

.horizontal.large-empty ul.steps-indicator li.completed .step-indicator {
    border: 2px solid #339933 !important;
    color: #339933 !important;
}

.horizontal.large-empty ul.steps-indicator li.navigable a:hover .step-indicator {
    border: 2px solid #cdcdcd !important;
}

.horizontal.large-empty ul.steps-indicator li.navigable.optional a:hover .step-indicator {
    border: 2px solid #12e212 !important;
}

.horizontal.large-empty ul.steps-indicator li.navigable.done a:hover .step-indicator {
    border: 2px solid #267326 !important;
}

.horizontal.large-empty ul.steps-indicator li.navigable.current a:hover .step-indicator {
    border: 2px solid #207ef7 !important;
}

.horizontal.large-empty ul.steps-indicator li.navigable.editing a:hover .step-indicator {
    border: 2px solid #F3770F!important;
}

.horizontal.large-empty ul.steps-indicator li.navigable.completed a:hover .step-indicator {
    border: 2px solid #267326 !important;
}

ul.steps-indicator li a .label {
    color: none;
    line-height: 14px;
    font-size: 13px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    transition: 0.25s;
    /* margin-top:10px;
         margin-bottom: 10px; */
}

ul.steps-indicator li.navigable a:hover .label {
    color: #fff;
}

.horizontal ul.steps-indicator li a .label {
    display: inline-block;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;
}

.horizontal.large-empty ul.steps-indicator li a .label :not(:last-child):after {
    background-color: transparent !important;
}

.horizontal.large-empty ul.steps-indicator li.done:not(:last-child):after {
    background-color: #ffffffad !important;
}

.horizontal.large-empty ul.steps-indicator li a .label {
    color: transparent !important;
}

.horizontal.large-empty ul.steps-indicator li.optional a .label {
    color: #ffffffad !important;
    font-size: 14px;
}

.horizontal.large-empty ul.steps-indicator li.done a .label {
    color: #ffffffad !important;
    font-size: 14px;
}

.horizontal.large-empty ul.steps-indicator li.current a .label {
    color: #fff !important;
    font-size: 14px;
    letter-spacing: 1px;
}

.horizontal.large-empty ul.steps-indicator li.editing a .label {
    color: #F3770F !important;
}

aw-wizard-step {
    padding: 30px !important;
    /* background: white; */
}

.form-container {
    background: white;
    // max-height: 800px;
}

.container-row {
    top: 550px !important;
}

.badge-btn.active[_ngcontent-serverApp-c156] {
    background: #2e8746!important;
    color: white !important;
    border: 1px solid #fff !important;
}

.badge-btn[_ngcontent-serverApp-c156] {
    border: 1px solid #207ef7 !important;
    padding: 10px 30px;
    color: #207ef7 !important;
    border-radius: 16px !important;
}

.badge-btn.active {
    background: #214583 !important;
    color: #fff;
}

@media only screen and (max-width: 767px)and (min-width: 100px) {
    .assureur {
        margin: 5px !important;
    }
    .horizontal ul.steps-indicator li a .label {
        // color: transparent !important; 
        // font-size: 12px !important;
        // line-height: 5px !important; 
        display: none !important;
    }
    aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li .step-indicator {
        top: -50px;
        left: calc(50% - 25px);
        position: absolute;
        width: 50px;
        height: 50px;
        text-align: center;
        vertical-align: middle;
        line-height: 46px;
        transition: 0.25s;
        border-radius: 70% !important;
        border: 2px solid white;
        color: white;
    }
    .wizard-steps {
        max-height: 1000px;
        // overflow-y:scroll;
    }
}

.pack-description {
    max-height: 90px;
}

.box-part:hover {
    //  border: 2px solid #204481;
    cursor: grab;
}

.box-part .c-title {
    margin-bottom: 20px !important;
}

.box-part .c-title h4 {
    color: #2e8746 !important;
    font-size: 17px !important;
}

.devis-content {
    // max-height: 250px;
    // overflow-y: scroll;
    overflow-x: none !important;
    margin-bottom: 30px;
    .img-section {
        height: 15vh;
    }
}

.bs-popover-top {
    max-width: 340px !important;
}

.heart {
    height: 10vh;
    // width: 10vh;
}

.card {
    box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
}

.modal-content {
    background: transparent !important;
}

button:focus {
    outline: 0px dotted !important;
    outline: 0px auto -webkit-focus-ring-color !important;
}

.pack-row {
    //  max-height: 500px; overflow-y: scroll;
    .pack-col {
        a {
            text-decoration: none;
            color: #cc0700;
            border-bottom: 2px solid #cc0700;
            background-color: #214583;
        }
        .box {
            padding: 60px 0px;
        }
        .box-part {
            background: #FFF;
            border-radius: 0;
            padding: 20px 10px;
            // margin:30px 0px;
            box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
        }
        .text {
            margin: 20px 0px;
        }
        .fa {
            color: #4183D7;
        }
    }
}

.btn-phone {
    margin-bottom: 50px;
    border-radius: 0 !important;
    margin-right: 50px;
    background: #3a7efd !important;
    color: white !important;
    border: none !important;
    color: white;
    height: 50px;
    margin-top: 50px;
    width: 250px;
    bottom: 20px !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.modal-content {
    border: none !important;
}

.assureur-content {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: none !important;
}

.img-section img {
    height: 100px;
}

@media only screen and (max-height: 810px)and (min-height: 800px) {
    .container-row {
        top: 570px !important;
    }
}

@media only screen and (max-height: 820px)and (min-height: 811px) {
    .container-row {
        top: 580px !important;
    }
}

@media only screen and (max-height: 830px)and (min-height: 821px) {
    .container-row {
        top: 590px !important;
    }
}

@media only screen and (max-height: 870px)and (min-height: 831px) {
    .container-row {
        top: 610px !important;
    }
}

@media only screen and (max-height: 900px)and (min-height: 871px) {
    .container-row {
        top: 630px !important;
    }
}

@media only screen and (max-height: 930px)and (min-height: 901px) {
    .container-row {
        top: 650px !important;
    }
}

@media only screen and (max-height: 960px)and (min-height: 931px) {
    .container-row {
        top: 670px !important;
    }
}

@media only screen and (max-height: 990px)and (min-height: 961px) {
    .container-row {
        top: 690px !important;
    }
}

@media only screen and (max-height: 1020px)and (min-height: 991px) {
    .container-row {
        top: 721px !important;
    }
}

@media only screen and (max-height: 1050px)and (min-height: 1021px) {
    .container-row {
        top: 751px !important;
    }
}

@media only screen and (max-height: 1090px)and (min-height: 1051px) {
    .container-row {
        top: 781px !important;
    }
}

@media only screen and (max-height: 1131px)and (min-height: 1091px) {
    .container-row {
        top: 811px !important;
    }
}

@media only screen and (max-height: 1201px)and (min-height: 1132px) {
    .container-row {
        top: 851px !important;
    }
}

@media only screen and (max-height: 1301px)and (min-height: 1202px) {
    .container-row {
        top: 891px !important;
    }
}

@media only screen and (max-height: 1402px)and (min-height: 1302px) {
    .container-row {
        top: 941px !important;
    }
}

@media only screen and (max-height: 1502px)and (min-height: 1403px) {
    .container-row {
        top: 1021px !important;
    }
}

@media only screen and (max-height: 1602px)and (min-height: 1503px) {
    .container-row {
        top: 1061px !important;
    }
}

@media only screen and (max-height: 1702px)and (min-height: 1603px) {
    .container-row {
        top: 1111px !important;
    }
}

@media only screen and (max-height: 1802px)and (min-height: 1703px) {
    .container-row {
        top: 1151px !important;
    }
}

@media only screen and (max-height: 2002px)and (min-height: 1803px) {
    .container-row {
        top: 1291px !important;
    }
}

@media only screen and (max-height: 2402px)and (min-height: 2003px) {
    .container-row {
        top: 1591px !important;
    }
}

@media only screen and (max-height: 2602px)and (min-height: 2403px) {
    .container-row {
        top: 1701px !important;
    }
}

@media only screen and (max-height: 2802px)and (min-height: 2603px) {
    .container-row {
        top: 1901px !important;
    }
}

@media only screen and (max-height: 3002px)and (min-height: 2803px) {
    .container-row {
        top: 2101px !important;
    }
}

.swal2-popup {
    padding: 1em 0 !important;
    width: 27em !important;
}

.swal2-icon {
    width: 4em !important;
    height: 4em !important;
    margin: 1em auto 1em !important;
}

.swal2-title {
    font-size: 1.75em !important;
}

.swal2-content {
    margin: 0px 50px !important;
}

.swal2-styled.swal2-cancel {
    background-color: #d93232 !important;
}

.form-group {
    i {
        border-top-left-radius: 20px !important;
        padding: 8.5px !important;
    }
}

body::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
    // background: orange;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: #1e293b;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 2px solid #f2f2f2;
    /* creates padding around scroll thumb */
}

.form-group input,
.form-group textarea,
.form-group select {
    font-size: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 100%;
    border-bottom-right-radius: 20px !important;
}

.form-group input,
.form-group textarea,
.form-group select {
    font-size: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 100%;
    border-bottom-right-radius: 20px !important;
}

.c-roundbtn {
    border-radius: 25px !important;
}

.c-titlePaiement {
    font-size: 20px !important;
}

.c-moyenPaiementimg {
    width: 100% !important;
    height: 100% !important;
    border-radius: 15px !important;
}

.c-roundbtn {
    border-radius: 25px !important;
}

.tailleImage {
    width: 160px;
}

.default-btn1 {
    margin-right: 14px !important;
    background-color: transparent !important;
    color: #ffff !important;
    border: 2px solid #fff !important;
    font-size: 20px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    text-decoration: none;
}

// ::ng-deep .css-14dz7n {
// }
.muiBox-root {
    .wrapper {
        color: white !important;
        width: auto !important;
        background-image: linear-gradient(80deg, var(--ColorDefault), var(--ColorDefault)) !important;
    }
}

.avantage {
    color: white;
}

.titre_card {
    color: #000 !important;
}

.contact_titre {
    color: white !important;
}

.contact_value {
    color: white !important;
    text-decoration: none;
}

.margeTop {
    margin-top: 20px;
}

.rappel {
    cursor: pointer;
    color: var(--ColorDefault) !important;
    &::before {
        border: 1px solid var(--ColorDefault) !important;
    }
    &:hover {
        color: #ffffff !important;
    }
}

.modal-content {
    align-items: center !important;
}

.colorChoisir {
    color: var(--ColorDefault) !important;
}

.modal-xl {
    max-width: 1000px !important;
}

//start parametrage
.colorTextDefault {
    color: var(--ColorDefault) !important;
}

.colorBackgroundDefault {
    background-color: var(--ColorDefault) !important;
}

.colorTextPrimary {
    color: var(--ColorTertiary);
}

.colorBackgroundPrimary {
    background-color: var(--ColorPrimary) !important;
}

.colorTextSecondary {
    color: var(--ColorSecondary) !important;
}

.colorBackgroundSecondary {
    background-color: var(--ColorSecondary) !important;
}

.colorTextTertiary {
    color: var(--ColorTertiary) !important;
}

.colorBackgroundTertiary {
    background-color: var(--ColorTertiary) !important;
}

.imageLogo {
    background-image: var(--LogoImage) !important;
    background-size: contain;
    height: 90px;
    background-repeat: no-repeat;
}

.initialLogo {
    background-image: var(--LogoImage) !important;
    background-size: contain;
    height: 90px;
    background-repeat: no-repeat;
}

//end parametrage