/*
@File: Truz Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - About Area CSS
** - Services Area CSS
** - Partner Area CSS
** - Why Choose Us Area CSS
** - CTR Area CSS
** - Pricing Area CSS
** - Quote Area CSS
** - Find Agent Area CSS
** - Feedback Area CSS
** - Our Mission Area CSS
** - Team Area CSS
** - Events Area CSS
** - Events Details Area CSS
** - Achievements Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Case Study Area CSS
** - Case Study Details Area CSS
** - Page Title Area CSS
** - Pagination Area CSS
** - Sidebar Widget Area CSS
** - Insurance Details Area CSS
** - FAQ Area CSS
** - 404 Error Area CSS
** - Contact Area CSS
** - Subscribe Area CSS
** - Footer Area CSS
** - Go Top CSS
*/


/*================================================
Default CSS
=================================================*/

$main-font-family: 'Open Sans',
sans-serif;
$optional-font-family: 'Roboto',
sans-serif;
$main-color: var(--ColorDefault);
$black-color: var(--ColorPrimary);
$white-color: #ffffff;
$paragraph-color: #666666;
$font-size: 15px;
$transition: .5s;
body {
    padding: 0;
    margin: 0;
    color: $black-color;
    font: {
        family: $main-font-family;
        size: $font-size;
    }
    ;
}

a {
    outline: 0 !important;
    transition: $transition;
    color: $black-color;
    text-decoration: none;
    &:hover {
        color: $main-color;
        text-decoration: none;
    }
}

p {
    color: $paragraph-color;
    margin-bottom: 12px;
    line-height: 1.8;
    font-size: $font-size;
    &:last-child {
        margin-bottom: 0;
    }
}

button,
input {
    outline: 0 !important;
}

:focus {
    outline: 0 !important;
}

.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
    ;
}

.pb-70 {
    padding-bottom: 70px;
}

.bg-black-color {
    background-color: $black-color;
}

.bg-f8f8f8 {
    background-color: #f8f8f8;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black-color;
    font: {
        family: $optional-font-family;
    }
    ;
}

.bg-fafafa {
    background-color: #fafafa;
}

img {
    max-width: 100%;
}

.d-table {
    width: 100%;
    height: 100%;
    &-cell {
        vertical-align: middle;
    }
}


/*section-title*/

.section-title {
    text-align: center;
    max-width: 700px;
    margin: {
        bottom: 55px;
        left: auto;
        right: auto;
    }
    ;
    .sub-title {
        color: $main-color;
        display: block;
        margin-bottom: 10px;
        font: {
            size: 17px;
        }
        ;
    }
    h2 {
        margin-bottom: 0;
        font: {
            size: 42px;
            weight: 900;
        }
        ;
    }
    p {
        max-width: 610px;
        font-size: 16px;
        margin: {
            left: auto;
            top: 8px;
            bottom: 0;
            right: auto;
        }
        ;
    }
}


/*default-btn*/

.default-btn {
    text-decoration: none;
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $white-color;
    background-color: $black-color;
    transition: $transition;
    border-radius: 5px;
    font: {
        weight: 700;
        size: $font-size;
        family: $optional-font-family
    }
    padding: {
        left: 30px;
        right: 30px;
        top: 13px;
        bottom: 13px;
    }
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $main-color;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    &:hover,
    &:focus {
        color: $white-color;
        span {
            width: 225%;
            height: 562.5px;
        }
    }
}

.optional-btn {
    text-decoration: none;
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $black-color;
    background-color: transparent;
    transition: $transition;
    border-radius: 5px;
    font: {
        weight: 700;
        size: $font-size;
        family: $optional-font-family
    }
    padding: {
        left: 30px;
        right: 30px;
        top: 13px;
        bottom: 13px;
    }
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $main-color;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        border-radius: 5px;
        height: 100%;
        border: 1px solid $black-color;
        z-index: -1;
    }
    &:hover,
    &:focus {
        color: $white-color;
        span {
            width: 225%;
            height: 562.5px;
        }
    }
}

.optional-btn-home {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $black-color;
    background-color: transparent;
    transition: $transition;
    border-radius: 5px;
    font: {
        weight: 700;
        size: $font-size;
        family: $optional-font-family
    }
    padding: {
        left: 30px;
        right: 30px;
        top: 13px;
        bottom: 13px;
    }
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $main-color;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        // border-radius: 5px;
        height: 100%;
        // border: 1px solid $black-color;
        z-index: -1;
    }
    &:hover,
    &:focus {
        color: $white-color;
        // border: none !important;
        span {
            width: 225%;
            height: 562.5px;
        }
    }
}


/*form-control*/

.form-control {
    height: 50px;
    background-color: $white-color;
    border: 1px solid #eeeeee;
    border-radius: 0;
    color: $black-color;
    box-shadow: unset !important;
    padding-left: 15px;
    font: {
        family: $optional-font-family;
        size: 14px;
        weight: 500;
    }
    ;
    &::placeholder {
        color: var(--ColorPrimary);
    }
}


/*================================================
Preloader Area CSS
=================================================*/

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    background: $black-color;
    top: 0;
    left: 0;
    .loader {
        position: absolute;
        top: 43%;
        left: 0;
        right: 0;
        transform: translateY(-43%);
        text-align: center;
        margin: 0 auto;
        width: 50px;
        height: 50px;
    }
    .box {
        width: 100%;
        height: 100%;
        background: $white-color;
        animation: animate .5s linear infinite;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
    }
    .shadow {
        width: 100%;
        height: 5px;
        background: #000;
        opacity: 0.1;
        position: absolute;
        top: 59px;
        left: 0;
        border-radius: 50%;
        animation: shadow .5s linear infinite;
    }
}

@keyframes loader {
    0% {
        left: -100px
    }
    100% {
        left: 110%;
    }
}

@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }
    25% {
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        transform: translateY(18px) scale(1, .9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        transform: translateY(0) rotate(90deg);
    }
}

@keyframes shadow {
    50% {
        transform: scale(1.2, 1);
    }
}


/*================================================
Navbar Area CSS
=================================================*/

.header-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    background-color: transparent;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 85%;
        background-color: #99999991;
        z-index: -1;
    }
}

.top-header {
    padding: {
        top: 10px;
        bottom: 15px;
    }
    ;
    .top-header-nav {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        li {
            display: inline-block;
            position: relative;
            margin-right: 16px;
            color: $white-color;
            padding-left: 12px;
            a {
                display: block;
                color: $white-color;
                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 9px;
                width: 6px;
                height: 6px;
                background-color: $main-color;
                border-radius: 50%;
            }
        }
    }
    .top-header-right-side {
        text-align: right;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                display: inline-block;
                text-align: left;
                position: relative;
                padding-left: 52px;
                color: $white-color;
                font-size: 14px;
                margin-right: 24px;
                .icon {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 50%;
                    color: $white-color;
                    font-size: 20px;
                    // background-color: $main-color;
                    // background-color: #FF7F2B;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transition: $transition;
                    transform: translateY(-50%);
                }
                span {
                    display: block;
                }
                a {
                    display: inline-block;
                    color: $white-color;
                    text-decoration: none;
                    font: {
                        size: 18px;
                        family: $optional-font-family;
                        weight: 700;
                    }
                    ;
                    &.default-btn {
                        background-color: $main-color;
                        color: $white-color;
                        top: 4px;
                        font: {
                            size: $font-size;
                        }
                        ;
                        span {
                            background: $white-color;
                        }
                        &:hover {
                            // color: $black-color;
                            color: var(--ColorDefault);
                        }
                    }
                }
                &:hover {
                    .icon {
                        background-color: $white-color;
                        color: $main-color;
                    }
                }
                &:last-child {
                    padding-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
    .top-header-logo {
        a {
            display: inline-block;
        }
    }
}

.truz-responsive-nav {
    display: none;
}

.truz-nav {
    background-color: transparent;
    .navbar {
        background-color: $white-color;
        box-shadow: -10px 10px rgba(255, 255, 255, .60);
        transition: $transition;
        padding: {
            right: 25px;
            top: 0;
            left: 25px;
            bottom: 0;
        }
        .navbar-brand {
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            font-family: $optional-font-family;
            margin-left: auto;
            .nav-item {
                position: relative;
                padding: 0;
                a {
                    font: {
                        size: 16.5px;
                        weight: 700;
                    }
                    color: $black-color;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 30px;
                        bottom: 30px;
                    }
                    margin: {
                        left: 12px;
                        right: 12px;
                    }
                    &:hover,
                    &:focus,
                    &.active {
                        color: $main-color;
                    }
                    i {
                        font-size: 10px;
                        margin-left: 1px;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover,
                &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 80px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;
                    margin-top: 20px;
                    border-top: 2px solid $main-color;
                    padding: 0;
                    li {
                        padding: 0;
                        a {
                            padding: 13px 20px 11px;
                            margin: 0;
                            position: relative;
                            color: $black-color;
                            background-color: transparent;
                            border-bottom: 1px dashed #e5e5e5;
                            font: {
                                size: 15px;
                                weight: 600;
                            }
                            &:hover,
                            &:focus,
                            &.active {
                                color: $white-color;
                                background-color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            left: -250px;
                            top: 15px;
                            opacity: 0;
                            visibility: hidden;
                            li {
                                a {
                                    color: $black-color;
                                    &:hover,
                                    &:focus,
                                    &.active {
                                        color: $white-color;
                                        background-color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: 220px;
                                    top: 15px;
                                    opacity: 0;
                                    visibility: hidden;
                                    li {
                                        a {
                                            color: $black-color;
                                            &:hover,
                                            &:focus,
                                            &.active {
                                                color: $white-color;
                                                background-color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 15px;
                                            opacity: 0;
                                            visibility: hidden;
                                            li {
                                                a {
                                                    color: $black-color;
                                                    &:hover,
                                                    &:focus,
                                                    &.active {
                                                        color: $white-color;
                                                        background-color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: -250px;
                                                    top: 15px;
                                                    opacity: 0;
                                                    visibility: hidden;
                                                    li {
                                                        a {
                                                            color: $black-color;
                                                            &:hover,
                                                            &:focus,
                                                            &.active {
                                                                color: $white-color;
                                                                background-color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 15px;
                                                            opacity: 0;
                                                            visibility: hidden;
                                                            li {
                                                                a {
                                                                    color: $black-color;
                                                                    &:hover,
                                                                    &:focus,
                                                                    &.active {
                                                                        color: $white-color;
                                                                        background-color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: -250px;
                                                                    top: 15px;
                                                                    opacity: 0;
                                                                    visibility: hidden;
                                                                    li {
                                                                        a {
                                                                            color: $black-color;
                                                                            &:hover,
                                                                            &:focus,
                                                                            &.active {
                                                                                color: $white-color;
                                                                                background-color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $white-color;
                                                                        background-color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        top: 0;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $white-color;
                                                                background-color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                top: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $white-color;
                                                        background-color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        top: 0;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $white-color;
                                                background-color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                top: 0;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $white-color;
                                        background-color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: 0;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $white-color;
                                background-color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: 0;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                        margin-top: 0;
                    }
                }
            }
        }
        .others-option {
            margin-left: 25px;
            margin-top: -5px;
            .option-item {
                color: $black-color;
                display: inline-block;
                position: relative;
                line-height: 1;
                .search-btn {
                    cursor: pointer;
                    transition: $transition;
                    color: $black-color;
                    font: {
                        size: 20px;
                        weight: 600;
                    }
                    &:hover {
                        color: $main-color;
                    }
                }
                .close-btn {
                    cursor: pointer;
                    display: none;
                    transition: $transition;
                    width: 20px;
                    color: $black-color;
                    font-size: 18px;
                    &.active {
                        display: block;
                    }
                    &:hover {
                        color: $main-color;
                    }
                }
            }
            .burger-menu {
                margin-left: 19px;
                cursor: pointer;
                display: inline-block;
                font-size: 22px;
                color: $black-color;
                transition: $transition;
                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}

.navbar-area {
    transition: $transition;
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        .truz-nav {
            .navbar {
                box-shadow: unset;
                padding: {
                    left: 0;
                    right: 0;
                }
                ;
            }
        }
    }
}

.header-style-two {
    &::before {
        background-color: $white-color;
    }
    .top-header {
        .top-header-nav {
            li {
                color: $black-color;
                a {
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
        .top-header-right-side {
            ul {
                li {
                    color: $paragraph-color;
                    a {
                        color: $black-color;
                        &:hover {
                            color: $main-color;
                        }
                        &.default-btn {
                            color: $white-color;
                            span {
                                background: $black-color;
                            }
                            &:hover {
                                color: $white-color;
                            }
                        }
                    }
                    &:hover {
                        .icon {
                            background-color: $black-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .truz-nav {
        .navbar {
            background-color: $black-color;
            box-shadow: -10px 10px rgba(0, 46, 91, .50);
            .navbar-nav {
                .nav-item {
                    a {
                        color: $white-color;
                        &:hover,
                        &:focus,
                        &.active {
                            color: $main-color;
                        }
                    }
                    &:hover,
                    &.active {
                        a {
                            color: $main-color;
                        }
                    }
                    .dropdown-menu {
                        li {
                            a {
                                color: $black-color;
                                &:hover,
                                &:focus,
                                &.active {
                                    color: $white-color;
                                    background-color: $main-color;
                                }
                            }
                            .dropdown-menu {
                                li {
                                    a {
                                        color: $black-color;
                                        &:hover,
                                        &:focus,
                                        &.active {
                                            color: $white-color;
                                            background-color: $main-color;
                                        }
                                    }
                                    .dropdown-menu {
                                        li {
                                            a {
                                                color: $black-color;
                                                &:hover,
                                                &:focus,
                                                &.active {
                                                    color: $white-color;
                                                    background-color: $main-color;
                                                }
                                            }
                                            .dropdown-menu {
                                                li {
                                                    a {
                                                        color: $black-color;
                                                        &:hover,
                                                        &:focus,
                                                        &.active {
                                                            color: $white-color;
                                                            background-color: $main-color;
                                                        }
                                                    }
                                                    .dropdown-menu {
                                                        li {
                                                            a {
                                                                color: $black-color;
                                                                &:hover,
                                                                &:focus,
                                                                &.active {
                                                                    color: $white-color;
                                                                    background-color: $main-color;
                                                                }
                                                            }
                                                            .dropdown-menu {
                                                                li {
                                                                    a {
                                                                        color: $black-color;
                                                                        &:hover,
                                                                        &:focus,
                                                                        &.active {
                                                                            color: $white-color;
                                                                            background-color: $main-color;
                                                                        }
                                                                    }
                                                                    .dropdown-menu {
                                                                        li {
                                                                            a {
                                                                                color: $black-color;
                                                                                &:hover,
                                                                                &:focus,
                                                                                &.active {
                                                                                    color: $white-color;
                                                                                    background-color: $main-color;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    &.active {
                                                                        a {
                                                                            color: $white-color;
                                                                            background-color: $main-color;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &.active {
                                                                a {
                                                                    color: $white-color;
                                                                    background-color: $main-color;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    &.active {
                                                        a {
                                                            color: $white-color;
                                                            background-color: $main-color;
                                                        }
                                                    }
                                                }
                                            }
                                            &.active {
                                                a {
                                                    color: $white-color;
                                                    background-color: $main-color;
                                                }
                                            }
                                        }
                                    }
                                    &.active {
                                        a {
                                            color: $white-color;
                                            background-color: $main-color;
                                        }
                                    }
                                }
                            }
                            &.active {
                                a {
                                    color: $white-color;
                                    background-color: $main-color;
                                }
                            }
                        }
                    }
                }
            }
            .others-option {
                .option-item {
                    color: $white-color;
                    .search-btn {
                        color: $white-color;
                        &:hover {
                            color: $main-color;
                        }
                    }
                    .close-btn {
                        color: $white-color;
                        &:hover {
                            color: $main-color;
                        }
                    }
                }
                .burger-menu {
                    color: $white-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .navbar-area {
        &.is-sticky {
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.3);
            background-color: $black-color !important;
        }
    }
}

.header-style-three {
    .truz-nav {
        .navbar-brand {
            display: none;
        }
        .navbar {
            .navbar-nav {
                margin-left: 0;
            }
            .others-option {
                margin-left: auto;
            }
        }
    }
}

.header-style-four {
    &::before {
        background-color: $white-color;
    }
    .top-header {
        .top-header-nav {
            li {
                color: $black-color;
                a {
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
        .top-header-right-side {
            ul {
                li {
                    color: $paragraph-color;
                    a {
                        color: $black-color;
                        &:hover {
                            color: $main-color;
                        }
                        &.default-btn {
                            color: $white-color;
                            span {
                                background: $black-color;
                            }
                            &:hover {
                                color: $white-color;
                            }
                        }
                    }
                    &:hover {
                        .icon {
                            background-color: $black-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .truz-nav {
        .navbar-brand {
            display: none;
        }
        .navbar {
            background-color: $black-color;
            box-shadow: -10px 10px rgba(0, 46, 91, .50);
            .navbar-nav {
                margin-left: 0;
                .nav-item {
                    a {
                        color: $white-color;
                        &:hover,
                        &:focus,
                        &.active {
                            color: $main-color;
                        }
                    }
                    &:hover,
                    &.active {
                        a {
                            color: $main-color;
                        }
                    }
                    .dropdown-menu {
                        li {
                            a {
                                color: $black-color;
                                &:hover,
                                &:focus,
                                &.active {
                                    color: $white-color;
                                    background-color: $main-color;
                                }
                            }
                            .dropdown-menu {
                                li {
                                    a {
                                        color: $black-color;
                                        &:hover,
                                        &:focus,
                                        &.active {
                                            color: $white-color;
                                            background-color: $main-color;
                                        }
                                    }
                                    .dropdown-menu {
                                        li {
                                            a {
                                                color: $black-color;
                                                &:hover,
                                                &:focus,
                                                &.active {
                                                    color: $white-color;
                                                    background-color: $main-color;
                                                }
                                            }
                                            .dropdown-menu {
                                                li {
                                                    a {
                                                        color: $black-color;
                                                        &:hover,
                                                        &:focus,
                                                        &.active {
                                                            color: $white-color;
                                                            background-color: $main-color;
                                                        }
                                                    }
                                                    .dropdown-menu {
                                                        li {
                                                            a {
                                                                color: $black-color;
                                                                &:hover,
                                                                &:focus,
                                                                &.active {
                                                                    color: $white-color;
                                                                    background-color: $main-color;
                                                                }
                                                            }
                                                            .dropdown-menu {
                                                                li {
                                                                    a {
                                                                        color: $black-color;
                                                                        &:hover,
                                                                        &:focus,
                                                                        &.active {
                                                                            color: $white-color;
                                                                            background-color: $main-color;
                                                                        }
                                                                    }
                                                                    .dropdown-menu {
                                                                        li {
                                                                            a {
                                                                                color: $black-color;
                                                                                &:hover,
                                                                                &:focus,
                                                                                &.active {
                                                                                    color: $white-color;
                                                                                    background-color: $main-color;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    &.active {
                                                                        a {
                                                                            color: $white-color;
                                                                            background-color: $main-color;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &.active {
                                                                a {
                                                                    color: $white-color;
                                                                    background-color: $main-color;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    &.active {
                                                        a {
                                                            color: $white-color;
                                                            background-color: $main-color;
                                                        }
                                                    }
                                                }
                                            }
                                            &.active {
                                                a {
                                                    color: $white-color;
                                                    background-color: $main-color;
                                                }
                                            }
                                        }
                                    }
                                    &.active {
                                        a {
                                            color: $white-color;
                                            background-color: $main-color;
                                        }
                                    }
                                }
                            }
                            &.active {
                                a {
                                    color: $white-color;
                                    background-color: $main-color;
                                }
                            }
                        }
                    }
                }
            }
            .others-option {
                margin-left: auto;
                .option-item {
                    color: $white-color;
                    .search-btn {
                        color: $white-color;
                        &:hover {
                            color: $main-color;
                        }
                    }
                    .close-btn {
                        color: $white-color;
                        &:hover {
                            color: $main-color;
                        }
                    }
                }
                .burger-menu {
                    color: $white-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .navbar-area {
        &.is-sticky {
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.3);
            background-color: $black-color !important;
        }
    }
}

.search-overlay {
    display: none;
    &.search-popup {
        position: absolute;
        top: 100%;
        width: 330px;
        right: 0;
        background: $white-color;
        z-index: 2;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 31px;
        .search-form {
            position: relative;
            .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $black-color;
                outline: 0;
                transition: $transition;
                padding: {
                    left: 15px;
                }
                ;
                &:focus {
                    border-color: $main-color;
                }
            }
            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;
                padding: 0;
                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
    }
}

.sidebar-modal {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    overflow: hidden;
    .sidebar-modal-inner {
        position: absolute;
        right: -100%;
        top: 0;
        width: 30%;
        overflow-y: scroll;
        height: 100%;
        background-color: $white-color;
        transition: 1.5s;
        z-index: 1;
        padding: {
            top: 80px;
            bottom: 40px;
            left: 40px;
            right: 40px;
        }
        ;
        .close-btn {
            display: inline-block;
            position: absolute;
            right: 35px;
            top: 20px;
            font-size: 14px;
            transition: $transition;
            color: $black-color;
            cursor: pointer;
            width: 35px;
            text-align: center;
            height: 35px;
            border-radius: 50%;
            border: 1px solid #eeeeee;
            line-height: 34px;
            &:hover {
                background-color: red;
                border-color: red;
                color: $white-color;
            }
        }
    }
    .sidebar-about-area {
        margin: {
            bottom: 40px;
        }
        .title {
            h2 {
                margin-bottom: 12px;
                font: {
                    size: 20px;
                    weight: 900;
                }
            }
            p {
                font-size: 14px;
            }
        }
    }
    .sidebar-instagram-feed {
        margin: {
            bottom: 40px;
        }
        h2 {
            margin-bottom: 25px;
            font: {
                size: 20px;
                weight: 900;
            }
        }
        ul {
            display: -ms-flexbox;
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            list-style-type: none;
            margin: {
                left: -5px;
                right: -5px;
                bottom: 0;
                top: -10px;
            }
            li {
                flex: 0 0 25%;
                max-width: 25%;
                padding: {
                    left: 5px;
                    right: 5px;
                    top: 10px;
                }
                a {
                    display: block;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #000000;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: .50;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .sidebar-contact-area {
        .sidebar-contact-info {
            text-align: center;
            .contact-info-content {
                h2 {
                    margin-bottom: 0;
                    font: {
                        size: 24px;
                        weight: 700;
                    }
                    a {
                        display: inline-block;
                        color: $main-color;
                        &:hover {
                            color: $black-color;
                        }
                        &:not(:first-child) {
                            color: $black-color;
                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                    span {
                        display: block;
                        color: $paragraph-color;
                        margin: {
                            top: 10px;
                            bottom: 10px;
                        }
                        font: {
                            size: 14px;
                            weight: 500;
                        }
                    }
                }
                .social {
                    padding-left: 0;
                    list-style-type: none;
                    margin: {
                        bottom: 0;
                        top: 20px;
                    }
                    li {
                        display: inline-block;
                        margin: 0 3px;
                        padding-left: 0;
                        a {
                            width: 35px;
                            height: 35px;
                            line-height: 33px;
                            border: 1px solid #dadada;
                            border-radius: 50%;
                            color: #aba5a5;
                            display: block;
                            i {
                                font-size: 14px;
                            }
                            &:hover {
                                color: $white-color;
                                border-color: $main-color;
                                background-color: $main-color;
                            }
                        }
                    }
                }
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        background-color: rgba(0, 0, 0, .8);
        z-index: -1;
        height: 100%;
        transition: 1s;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        .sidebar-modal-inner {
            right: 0;
        }
        &::before {
            width: 100%;
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
    .truz-responsive-nav {
        display: block;
        .truz-responsive-menu {
            position: relative;
            &.mean-container {
                .mean-nav {
                    margin-top: 61px;
                    ul {
                        font-size: 15px;
                        li {
                            a {
                                &.active {
                                    color: $main-color;
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .others-option {
                        position: absolute;
                        right: 55px;
                        top: 13px;
                        .option-item {
                            color: $black-color;
                            display: inline-block;
                            position: relative;
                            line-height: 1;
                            .search-btn {
                                cursor: pointer;
                                transition: $transition;
                                color: $black-color;
                                font: {
                                    size: 20px;
                                    weight: 600;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            .close-btn {
                                cursor: pointer;
                                display: none;
                                transition: $transition;
                                width: 20px;
                                color: $black-color;
                                font-size: 18px;
                                &.active {
                                    display: block;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                        .burger-menu {
                            display: none;
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    height: 306px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: $black-color;
                    span {
                        background: $black-color;
                    }
                }
            }
        }
        .logo {
            position: relative;
            width: 50%;
            z-index: 999;
        }
    }
    .navbar-area {
        background-color: $white-color;
        padding: {
            top: 15px;
            bottom: 15px;
        }
        ;
    }
    .truz-nav {
        display: none;
    }
    .search-overlay {
        &.search-popup {
            width: 270px;
            right: -68px;
            .search-form {
                .search-input {
                    width: 94%;
                }
            }
        }
    }
    .header-style-two {
        .navbar-area {
            background-color: $black-color;
        }
        .truz-responsive-nav {
            .truz-responsive-menu {
                &.mean-container {
                    .mean-nav {
                        .others-option {
                            .option-item {
                                color: $white-color;
                                .search-btn {
                                    color: $white-color;
                                    &:hover {
                                        color: $main-color;
                                    }
                                }
                                .close-btn {
                                    color: $white-color;
                                    &:hover {
                                        color: $main-color;
                                    }
                                }
                            }
                            .burger-menu {
                                color: $white-color;
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                }
            }
            .mean-container {
                a {
                    &.meanmenu-reveal {
                        color: $white-color;
                        span {
                            background: $white-color;
                        }
                    }
                }
            }
        }
    }
    .header-style-four {
        .navbar-area {
            background-color: $black-color;
        }
        .truz-responsive-nav {
            .truz-responsive-menu {
                &.mean-container {
                    .mean-nav {
                        .others-option {
                            .option-item {
                                color: $white-color;
                                .search-btn {
                                    color: $white-color;
                                    &:hover {
                                        color: $main-color;
                                    }
                                }
                                .close-btn {
                                    color: $white-color;
                                    &:hover {
                                        color: $main-color;
                                    }
                                }
                            }
                            .burger-menu {
                                color: $white-color;
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                }
            }
            .mean-container {
                a {
                    &.meanmenu-reveal {
                        color: $white-color;
                        span {
                            background: $white-color;
                        }
                    }
                }
            }
        }
    }
}


/*================================================
Main Banner Area CSS
=================================================*/

.main-banner {
    height: 970px;
    position: relative;
    z-index: 2;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: transparent url(../../assets/img/banner-shape.png) center center no-repeat;
        opacity: 0.5;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 360px;
        z-index: 1;
        background: {
            image: url(../../assets/img/banner-white-shape.png);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
        ;
    }
}

.item-bg1 {
    background: {
        image: url(../../assets/images/entete13.jpg);
    }
    ;
}

.item-bg2 {
    background: {
        image: url(../../assets/images/entete6.jpg);
        position: right top !important;
    }
    ;
}

.item-bg3 {
    background: {
        image: url(../../assets/images/assets-images/imageSlide1.jpg);
        position: center top !important;
    }
    ;
}

.item-bg4 {
    background: {
        image: url(../../assets/images/assets-images/imageSlide1.jpg);
        position: top left!important;
    }
    ;
}

.item-bg5 {
    background: {
        image: url(../../assets/images/assets-images/imageSlide1.jpg);
    }
    ;
}

.item-bg6 {
    background: {
        image: url(../../assets/images/entete6ref.jpg);
    }
    ;
}

.item-bg16 {
    background: {
        image: url(../../assets/images/assets-images/entete50.jpg);
    }
    ;
}

.item-bg16-tous {
    background: {
        image: url(../../assets/images/assets-images/tousrisque.jpg);
    }
    ;
}

.item-bg166 {
    background: {
        image: url(../../assets/images/assets-images/entete74.jpg);
    }
    ;
}

.item-responsable {
    background: {
        image: url(../../assets/images/assets-images/responsable.jpg);
    }
    ;
}

.item-bg1666 {
    background: {
        image: url(../../assets/images/assets-images/entete80.jpg);
    }
    ;
}

.item-bg16666 {
    background: {
        image: url(../../assets/images/assets-images/entete85.jpg);
    }
    ;
}

.item-bg7 {
    background: {
        image: url(../../assets/img/main-banner-image/main-banner7.jpg);
    }
    ;
}

.banner-section {
    height: 870px;
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: transparent url(../../assets/img/banner-shape.png) center center no-repeat;
    }
    .main-banner-content {
        margin-top: 150px;
    }
}

.main-banner-content {
    position: relative;
    z-index: 2;
    margin-top: 10px;
    max-width: 785px;
    .sub-title {
        display: block;
        color: $white-color;
        margin-bottom: 13px;
        font: {
            size: 16px;
        }
        ;
    }
    h1 {
        color: $white-color;
        font: {
            size: 55px;
            weight: 900;
        }
        ;
    }
    .btn-box {
        margin-top: 32px;
        .default-btn {
            margin-right: 14px;
            background-color: $white-color;
            color: $black-color;
            &:hover,
            &:focus {
                color: $white-color;
            }
        }
        .optional-btn {
            color: $white-color;
            &::before {
                border-color: $white-color;
            }
        }
    }
    p {
        color: $white-color;
        max-width: 485px;
        margin: {
            bottom: 0;
            top: 13px;
        }
        ;
    }
    &.text-center {
        margin: {
            left: auto;
            right: auto;
        }
        ;
        p {
            max-width: 530px;
            margin: {
                left: auto;
                right: auto;
            }
            ;
        }
        .btn-box {
            .default-btn,
            .optional-btn {
                margin: {
                    left: 5px;
                    right: 5px;
                }
                ;
            }
        }
    }
}

.banner-content {
    position: relative;
    z-index: 2;
    margin-top: 150px;
    max-width: 785px;
    .sub-title {
        display: block;
        color: $white-color;
        margin-bottom: 17px;
        border-left: 3px solid $main-color;
        padding-left: 10px;
        font: {
            size: 16px;
        }
        ;
    }
    h1 {
        color: $white-color;
        font: {
            size: 55px;
            weight: 900;
        }
        ;
    }
    .btn-box {
        margin-top: 40px;
        position: relative;
        .default-btn {
            margin-right: 25px;
            background-color: $white-color;
            color: $black-color;
            padding: {
                top: 15px;
                bottom: 15px;
                right: 35px;
                left: 35px;
            }
            ;
            &:hover,
            &:focus {
                color: $white-color;
            }
        }
        .video-btn {
            display: inline-block;
            color: $white-color;
            position: absolute;
            top: 2px;
            font: {
                weight: 600;
                size: 16px;
            }
            ;
            span {
                display: inline-block;
                margin-right: 5px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                position: relative;
                z-index: 1;
                text-align: center;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, .2);
                color: $white-color;
                font-size: 20px;
                transition: $transition;
                &::after,
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    border: 1px solid rgba(255, 255, 255, .2);
                }
                &::before {
                    animation: ripple 2s linear infinite
                }
                &::after {
                    animation: ripple 2s linear 1s infinite
                }
            }
            &:hover {
                span {
                    background-color: $white-color;
                    color: $black-color;
                }
            }
        }
    }
    p {
        color: $white-color;
        max-width: 485px;
        margin: {
            bottom: 0;
            top: 17px;
        }
        ;
    }
}

.main-banner-section {
    height: 969px;
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    .main-banner-content {
        margin-top: 130px;
    }
}

.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            [class*=owl-] {
                position: absolute;
                left: 50px;
                top: 45%;
                transform: translateY(-45%);
                background-color: transparent !important;
                transition: $transition;
                margin: 0;
                padding: 0 0 0 0 !important;
                opacity: .50;
                color: $white-color;
                font-size: 30px;
                overflow: hidden;
                &.owl-prev {
                    padding: 0 25px 0 0 !important;
                    &::before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 14px;
                        transition: transform .4s ease;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0%, 100%);
                        transition: transform .4s ease;
                        font-size: 14px;
                    }
                    &:hover {
                        &::before {
                            transform: translateY(-190%);
                        }
                        &::after {
                            transform: translate(0, -50%);
                        }
                    }
                }
                &.owl-next {
                    left: auto;
                    right: 50px;
                    padding: 0 0 0 25px !important;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 14px;
                        transition: transform .4s ease;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0%, 100%);
                        transition: transform .4s ease;
                        font-size: 14px;
                    }
                    &:hover {
                        &::before {
                            transform: translateY(-190%);
                        }
                        &::after {
                            transform: translate(0, -50%);
                        }
                    }
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.home-slides-two {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            [class*=owl-] {
                position: absolute;
                left: 50px;
                top: 58%;
                transform: translateY(-58%);
                background-color: transparent !important;
                transition: $transition;
                margin: 0;
                padding: 0 0 0 0 !important;
                opacity: .50;
                color: $white-color;
                font-size: 30px;
                overflow: hidden;
                &.owl-prev {
                    padding: 0 25px 0 0 !important;
                    &::before {
                        content: 'Prev';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 14px;
                        transition: transform .4s ease;
                    }
                    &::after {
                        content: 'Prev';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0%, 100%);
                        transition: transform .4s ease;
                        font-size: 14px;
                    }
                    &:hover {
                        &::before {
                            transform: translateY(-190%);
                        }
                        &::after {
                            transform: translate(0, -50%);
                        }
                    }
                }
                &.owl-next {
                    left: auto;
                    right: 50px;
                    padding: 0 0 0 25px !important;
                    &::before {
                        content: 'Next';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 14px;
                        transition: transform .4s ease;
                    }
                    &::after {
                        content: 'Next';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0%, 100%);
                        transition: transform .4s ease;
                        font-size: 14px;
                    }
                    &:hover {
                        &::before {
                            transform: translateY(-190%);
                        }
                        &::after {
                            transform: translate(0, -50%);
                        }
                    }
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.shape1 {
    position: absolute;
    left: 35px;
    top: 26%;
    z-index: -1;
    animation: moveLeftBounce 10s linear infinite;
    opacity: .50;
}

.shape2 {
    position: absolute;
    right: 200px;
    top: 30%;
    z-index: -1;
    animation: moveBounce 10s linear infinite;
    opacity: .50;
}

.shape3 {
    position: absolute;
    left: 100px;
    bottom: 135px;
    z-index: -1;
    animation: moveBounce 10s linear infinite;
    opacity: .50;
}

.shape4 {
    position: absolute;
    right: 74px;
    bottom: 75px;
    z-index: -1;
    animation: moveLeftBounce 10s linear infinite;
    opacity: .50;
}

@keyframes ripple {
    0% {
        transform: scale(1)
    }
    75% {
        transform: scale(1.75);
        opacity: 1
    }
    100% {
        transform: scale(2);
        opacity: 0
    }
}

@keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(30px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(30px);
    }
    100% {
        transform: translateY(0px);
    }
}


/*================================================
About Area CSS
=================================================*/

.about-title {
    border-right: 1px solid #dedcde;
    padding-right: 50px;
    span {
        color: $main-color;
        font-size: 17px;
        display: block;
        margin-bottom: 10px;
    }
    h2 {
        margin-bottom: 0;
        font: {
            size: 42px;
            weight: 900;
        }
        ;
    }
}

.about-text {
    padding-left: 40px;
    p {
        font-size: 17px;
    }
    .read-more-btn {
        margin-top: 5px;
        display: inline-block;
        color: $main-color;
        font: {
            size: $font-size;
            weight: 700;
        }
        ;
        i {
            transition: $transition;
            display: inline-block;
        }
        &:hover {
            i {
                margin-left: 3px;
            }
        }
    }
}

.about-boxes-area {
    margin-top: 30px;
}

.single-about-box {
    margin-top: 30px;
    background-color: $white-color;
    padding: 30px;
    border-radius: 5px;
    text-align: center;
    transition: $transition;
    .icon {
        width: 90px;
        height: 100px;
        line-height: 100px;
        border-radius: 5px;
        background-color: #f8f8f8;
        color: $main-color;
        font-size: 50px;
        transition: $transition;
        margin: {
            left: auto;
            right: auto;
            bottom: 25px;
        }
        ;
    }
    h3 {
        margin-bottom: 10px;
        font: {
            size: 21px;
            weight: 900;
        }
        ;
        a {
            display: inline-block;
        }
    }
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
        .icon {
            background-color: $main-color;
            color: $white-color;
        }
    }
}

.about-image {
    position: relative;
    padding-bottom: 90px;
    img {
        &:nth-child(2) {
            position: absolute;
            right: 0;
            bottom: 0;
            border-radius: 0 10px 0 0;
        }
    }
}

.about-content {
    padding: {
        left: 15px;
    }
    ;
    span {
        display: block;
        // color: $main-color;
        color: var(--ColorPrimary);
        margin-bottom: 10px;
        font: {
            size: 17px;
        }
        ;
    }
    h2 {
        margin-bottom: 13px;
        font: {
            size: 42px;
            weight: 900;
        }
        ;
    }
    .default-btn {
        margin-top: 10px;
    }
}

.about-inner-area {
    margin-top: 30px;
}

.about-text-box {
    background-color: $white-color;
    padding: 30px;
    margin-top: 30px;
    border-radius: 5px;
    transition: $transition;
    position: relative;
    z-index: 1;
    h3 {
        color: var(--ColorDefault);
        margin-bottom: 17px;
        position: relative;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 12px;
        transition: $transition;
        font: {
            size: 22px;
            weight: 900;
        }
        ;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 50px;
            height: 1px;
            background-color: $main-color;
            transition: $transition;
        }
    }
    p {
        transition: $transition;
        font-size: 14.5px;
    }
    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $black-color;
        transform: scaleY(0);
        transform-origin: 50% 0;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
        border-radius: 5px;
    }
    &:hover {
        transform: translateY(-10px);
        h3 {
            color: $white-color;
            border-color: $white-color;
        }
        p {
            color: $white-color;
        }
        &::before {
            transform: scaleY(1);
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        }
    }
}

.about-img {
    position: relative;
    z-index: 1;
    padding: {
        right: 8px;
        left: 50px;
        bottom: 50px;
    }
    ;
    .text {
        position: absolute;
        right: 0;
        bottom: 42px;
        background-color: $main-color;
        color: $white-color;
        font: {
            size: 16px;
            weight: 500;
        }
        ;
        text-align: center;
        max-width: 250px;
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.4);
        padding: {
            top: 15px;
            bottom: 15px;
            left: 25px;
            right: 25px;
        }
        ;
        span {
            display: inline-block;
            font: {
                size: 20px;
                family: $optional-font-family;
                weight: 900;
            }
            ;
        }
    }
    .shape {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
}


/*================================================
Services Area CSS
=================================================*/

.single-services-box {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    z-index: 1;
    border-radius: 5px;
    background-color: $white-color;
    padding: 30px;
    transition: $transition;
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    .icon {
        font-size: 65px;
        transition: $transition;
        position: relative;
        z-index: 1;
        margin: {
            top: -15px;
            bottom: 12px;
        }
        ;
        .icon-bg {
            img {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 50%;
                transform: translateY(-50%);
                width: auto !important;
                display: inline-block !important;
                z-index: -1;
            }
        }
    }
    h3 {
        margin-bottom: 18px;
        position: relative;
        padding-bottom: 15px;
        transition: $transition;
        font: {
            size: 22px;
            weight: 900;
        }
        ;
        a {
            display: inline-block;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;
            background-color: #e1dfe1;
            height: 1px;
            width: 70px;
            transition: $transition;
        }
    }
    p {
        transition: $transition;
    }
    .read-more-btn {
        display: inline-block;
        margin-top: 10px;
        color: $black-color;
        font: {
            size: $font-size;
            weight: 700;
        }
        ;
        &:hover {
            letter-spacing: .5px;
        }
    }
    .box-shape {
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: auto !important;
            transition: $transition;
            display: inline-block !important;
            &:nth-child(2) {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &:hover {
        background-color: $black-color;
        .icon {
            color: $main-color;
        }
        h3 {
            color: $white-color;
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
        .read-more-btn {
            color: $main-color;
        }
        .box-shape {
            img {
                &:nth-child(1) {
                    opacity: 0;
                    visibility: hidden;
                }
                &:nth-child(2) {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.services-slides {
    &.owl-theme {
        .owl-nav {
            opacity: 0;
            visibility: hidden;
            margin-top: 0;
            transition: $transition;
            [class*=owl-] {
                position: absolute;
                left: -70px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                background-color: $black-color;
                transition: $transition;
                color: $white-color;
                font-size: 20px;
                width: 45px;
                height: 45px;
                line-height: 45px;
                border-radius: 50%;
                box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
                &.owl-next {
                    left: auto;
                    right: -70px;
                }
                .flaticon-left-chevron {
                    position: relative;
                    left: -2px;
                }
                .flaticon-right-chevron {
                    position: relative;
                    left: 2px;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                }
            }
        }
        .owl-dots {
            line-height: .01;
            margin: {
                top: 5px;
                bottom: 30px;
            }
            ;
            .owl-dot {
                span {
                    width: 15px;
                    height: 2px;
                    margin: 0 3px;
                    background-color: #afaaaf;
                    transition: $transition;
                    border-radius: 0;
                }
                &:hover {
                    span {
                        background-color: $black-color;
                    }
                }
                &.active {
                    span {
                        background-color: $black-color;
                        width: 25px;
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.services-boxes-area {
    margin-top: -180px;
    position: relative;
}

.single-box {
    text-align: center;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    z-index: 3;
    .content {
        border-radius: 38% 38% 0 0;
        padding: 110px 30px 35px;
        background-color: $white-color;
        position: relative;
        z-index: 1;
        margin-top: -125px;
        h3 {
            margin-bottom: 10px;
            position: relative;
            font: {
                size: 21px;
                weight: 900;
            }
            ;
            a {
                display: inline-block;
                color: #000000;
                text-decoration: none;
            }
        }
        p {
            margin: {
                top: 0;
                bottom: 0;
            }
            ;
        }
        .shape {
            position: absolute;
            left: 0;
            top: -55px;
            right: 0;
            margin: 0 auto;
            z-index: -1;
        }
        .icon {
            position: absolute;
            left: 0;
            right: 0;
            top: -15px;
            margin: 0 auto;
        }
    }
}

.services-box {
    margin-bottom: 30px;
    border-radius: 5px;
    transition: $transition;
    background-color: $white-color;
    box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .1);
    .image {
        overflow: hidden;
        border-radius: 5px 5px 0 0;
        img {
            border-radius: 5px 5px 0 0;
            transition: $transition;
        }
    }
    .content {
        padding: 30px;
        border-radius: 0 0 5px 5px;
        h3 {
            margin-bottom: 10px;
            position: relative;
            font: {
                size: 22px;
                weight: 900;
            }
            ;
            a {
                display: inline-block;
            }
        }
        .read-more-btn {
            display: inline-block;
            margin-top: 5px;
            color: $black-color;
            text-transform: capitalize;
            font: {
                size: $font-size;
                weight: 700;
            }
            ;
            &:hover {
                letter-spacing: .5px;
            }
            i {
                font-size: 12px;
            }
        }
    }
    &:hover {
        border-radius: 0;
        transform: translateY(-10px);
        .image {
            border-radius: 0;
            img {
                border-radius: 0;
                transform: scale(1.2) rotate(5deg);
            }
        }
        .content {
            border-radius: 0;
        }
    }
}


/*================================================
Partner Area CSS
=================================================*/

.partner-area {
    padding: {
        top: 60px;
        bottom: 60px;
    }
    ;
}

.partner-title {
    text-align: center;
    h2 {
        margin-bottom: 40px;
        font: {
            size: 30px;
            weight: 900;
        }
        ;
    }
}

.single-partner-item {
    text-align: center;
    a {
        display: block;
        img {
            width: auto !important;
            display: inline-block !important;
        }
    }
}


/*================================================
Why Choose Us Area CSS
=================================================*/

.why-choose-us-area {
    .container-fluid {
        padding: 0;
        .row {
            margin: 0;
            .col-lg-5,
            .col-lg-7 {
                padding: 0;
            }
        }
    }
}

.why-choose-us-content {
    background: {
        color: $black-color;
        image: url(../../assets/images/assets-images/degrader2.jpg);
        position: right center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    .content {
        max-width: 705px;
        overflow: hidden;
        padding: {
            left: 70px;
            top: 100px;
            bottom: 100px;
        }
        .title {
            margin-bottom: 10px;
            .sub-title {
                color: $main-color;
                font-size: 17px;
                display: block;
                margin-bottom: 10px;
            }
            h2 {
                color: $white-color;
                margin-bottom: 0;
                font: {
                    size: 35px;
                    weight: 900;
                }
                ;
            }
            p {
                color: $white-color;
                font-size: 16px;
                margin: {
                    bottom: 0;
                    top: 10px;
                }
                ;
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            margin: {
                left: -15px;
                right: -15px;
                bottom: 0;
                top: 0;
            }
            ;
            li {
                flex: 0 0 50%;
                max-width: 50%;
                color: #e5e5e5;
                position: relative;
                padding: {
                    left: 82px;
                    right: 15px;
                    top: 30px;
                }
                ;
                .icon {
                    position: absolute;
                    left: 15px;
                    top: 35px;
                    width: 50px;
                    height: 50px;
                    line-height: 49px;
                    transition: $transition;
                    border-radius: 50%;
                    color: $white-color;
                    background-color: $main-color;
                    text-align: center;
                    font-size: 28px;
                }
                span {
                    display: block;
                    margin-bottom: 6px;
                    color: $white-color;
                    font: {
                        size: 18px;
                        weight: 900;
                    }
                    ;
                }
                &:hover {
                    .icon {
                        background-color: $white-color;
                        color: $main-color;
                    }
                }
            }
        }
    }
}

.why-choose-us-image {
    width: 100%;
    height: 100%;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    &.bg1 {
        background: {
            image: url(../../assets/images/assets-images/imageSlide1.jpg);
        }
        ;
    }
    &.bg2 {
        background: {
            image: url(../../assets/images/assets-images/imageSlide2.jpg);
        }
        ;
    }
    &.bg3 {
        background: {
            image: url(../../assets/images/assets-images/entete23.jpg);
        }
        ;
    }
    img {
        width: auto !important;
        display: none !important;
    }
}

.why-choose-us-slides {
    height: 100%;
    .owl-stage-outer {
        width: 100%;
        height: 100%;
        .owl-stage {
            height: 100%;
            .owl-item {
                height: 100%;
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            opacity: 0;
            visibility: hidden;
            margin-top: 0;
            transition: $transition;
            [class*=owl-] {
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                background-color: transparent;
                transition: $transition;
                color: $white-color;
                font-size: 35px;
                &.owl-next {
                    left: auto;
                    right: 25px;
                }
                &:hover {
                    color: $main-color;
                    background-color: transparent;
                }
            }
        }
        .owl-dots {
            line-height: .01;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 25px;
            margin: {
                top: 0;
            }
            ;
            .owl-dot {
                span {
                    width: 15px;
                    height: 2px;
                    margin: 0 3px;
                    background-color: $white-color;
                    transition: $transition;
                    border-radius: 0;
                }
                &:hover {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background-color: $main-color;
                        width: 25px;
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}


/*================================================
CTR Area CSS
=================================================*/

.ctr-area {
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: $main-color;
    padding: {
        top: 150px;
        bottom: 150px;
    }
    ;
    .shape {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }
}

.ctr-content {
    border-radius: 5px;
    padding: 50px;
    position: relative;
    z-index: 2;
    max-width: 590px;
    background-color: $black-color;
    h2 {
        color: $white-color;
        font: {
            size: 42px;
            weight: 900;
        }
        ;
    }
    p {
        color: $white-color;
        font-size: 16px;
        margin-top: 5px;
    }
    .default-btn {
        margin-top: 15px;
        background-color: $main-color;
        span {
            background-color: $white-color;
        }
        &:hover,
        &:focus {
            color: $main-color;
        }
    }
}

.ctr-image {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}


/*================================================
Pricing Area CSS
=================================================*/

.single-pricing-box {
    margin-bottom: 30px;
    // background-color: $white-color;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    border-radius: 5px;
    text-align: center;
    padding-bottom: 40px;
    .pricing-header {
        // background-color: $black-color;
        border-radius: 5px 5px 0 0;
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding: {
            top: 80px;
            bottom: 80px;
        }
        ;
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
        ;
        // &.bg1 {
        //     background: {
        //         image: url(../../assets/img/achievements-img1.jpg);
        //     }
        //     ;
        // }
        // &.bg2 {
        //     background: {
        //         image: url(../../assets/img/achievements-img2.jpg);
        //     }
        //     ;
        // }
        // &.bg3 {
        //     background: {
        //         image: url(../../assets/img/achievements-img3.jpg);
        //     }
        //     ;
        // }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            z-index: -1;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            // background-color: $black-color;
            opacity: .65;
        }
        h3 {
            margin-bottom: 0;
            color: $white-color;
            position: relative;
            z-index: 1;
            font: {
                weight: 900;
                size: 22px;
            }
            ;
        }
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 105px;
            width: 100%;
            background: {
                // image: url(../../assets/img/pricing-shape.png);
                position: center center;
                size: cover;
                repeat: no-repeat;
            }
            ;
        }
    }
    .price {
        color: $black-color;
        margin-top: 20px;
        font: {
            size: 40px;
            weight: 900;
        }
        ;
        sub {
            text-transform: lowercase;
            bottom: 2px;
            font: {
                weight: 400;
                size: 16px;
            }
            ;
        }
    }
    .book-now-btn {
        margin-top: 20px;
        .default-btn {
            background-color: transparent;
            color: $main-color;
            border: 1px solid $main-color;
            padding: {
                left: 35px;
                right: 35px;
                top: 12px;
                bottom: 12px;
            }
            ;
            span {
                background-color: $main-color;
            }
            &:hover {
                color: $white-color;
            }
        }
    }
    .pricing-features-list {
        margin: {
            bottom: 0;
            top: 25px;
        }
        ;
        list-style-type: none;
        text-align: left;
        padding: {
            left: 40px;
            right: 40px;
        }
        ;
        li {
            color: $black-color;
            margin-bottom: 13px;
            font: {
                size: $font-size;
                weight: 400;
            }
            ;
            i {
                color: $main-color;
                margin-right: 4px;
                &.flaticon-cross-out {
                    font-size: 13px;
                    color: red;
                }
            }
            &.none-active {
                opacity: .76;
                i {
                    color: $black-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}


/*================================================
Quote Area CSS
=================================================*/

.quote-area {
    background: {
        image: url(../../assets/img/white-bg-line.jpg);
        position: center center;
        size: contain;
        repeat: repeat;
    }
    ;
}

.quote-content {
    padding-right: 15px;
    text-align: center;
    h2 {
        margin-bottom: 0;
        font: {
            size: 42px;
            weight: 900;
        }
        ;
    }
    p {
        font-size: 15.5px;
        margin: {
            top: 8px;
            bottom: 0;
        }
        ;
    }
    .image {
        margin-top: 35px;
    }
}

.tab {
    .tabs_item {
        display: none;
        &:first-child {
            display: block;
        }
    }
}

.quote-list-tab {
    margin-left: 15px;
    background-color: $white-color;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    padding: 30px;
    border-radius: 5px;
    .tabs {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        font-family: $optional-font-family;
        li {
            display: inline-block;
            font: {
                size: 17px;
                weight: 900;
            }
            ;
            a {
                display: block;
                padding: 10px 24.3px;
                background-color: transparent;
                color: #848484;
                &:hover {
                    color: $black-color;
                    background-color: #f9f9f9;
                }
            }
            &.current {
                a {
                    color: $black-color;
                    background-color: #f9f9f9;
                }
            }
        }
    }
    .tabs_item {
        background-color: #f9f9f9;
        padding: 25px;
        p {
            margin-bottom: 15px;
            font: {
                weight: 600;
                size: 14px;
            }
            ;
        }
        form {
            .form-group {
                margin-bottom: 15px;
            }
            .form-control {
                border: none;
                background-color: $white-color;
            }
            .default-btn {
                display: block;
                width: 100%;
                margin-top: 20px;
            }
            .nice-select {
                background-color: $white-color;
                color: $black-color;
                border-radius: 0;
                border: none;
                height: 50px;
                line-height: 53px;
                padding: 0 15px;
                font: {
                    size: 14px;
                    weight: 500;
                    family: $optional-font-family;
                }
                ;
                .list {
                    background-color: $white-color;
                    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
                    border-radius: 0;
                    margin-top: 0;
                    width: 100%;
                    height: 260px;
                    overflow-y: scroll;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                    }
                    ;
                    .option {
                        transition: $transition;
                        text-transform: capitalize;
                        font: {
                            size: $font-size;
                            family: $optional-font-family;
                            weight: 500;
                        }
                        ;
                        padding: {
                            left: 20px;
                            right: 20px;
                        }
                        ;
                        &:hover {
                            background-color: $main-color !important;
                            color: $white-color;
                        }
                        &.selected {
                            background-color: transparent;
                            font-weight: 600;
                        }
                    }
                }
                &:after {
                    right: 15px;
                    border-color: $black-color;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}

.quote-boxes-area {
    position: relative;
    z-index: 3;
    margin-top: -91px;
}

.quote-boxes-content {
    background-color: $white-color;
    box-shadow: 0 25px 50px 0 rgba(0, 0, 0, .07);
    border-radius: 5px;
    text-align: center;
    max-width: 800px;
    padding: 50px;
    position: relative;
    margin: {
        left: auto;
        right: auto;
    }
    ;
    h2 {
        margin-bottom: 0;
        // background-color: $main-color;
        background-color: #002e5b;
        color: $white-color;
        border-radius: 5px 5px 0 0;
        padding: 30px 30px 25px;
        font: {
            size: 30px;
            weight: 900;
        }
        ;
        margin: {
            top: -50px;
            right: -50px;
            left: -50px;
            bottom: 50px;
        }
        ;
    }
    form {
        position: relative;
        z-index: 1;
        .form-group {
            margin-bottom: 0;
        }
        .row {
            margin: {
                left: -4px;
                right: -4px;
            }
            ;
            .col-lg-4,
            .col-lg-5,
            .col-lg-3,
            .col-lg-7,
            .col-lg-12 {
                padding: {
                    left: 4px;
                    right: 4px;
                }
                ;
            }
        }
        .nice-select {
            background-color: $white-color;
            color: $black-color;
            border-radius: 0;
            border: none;
            box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
            height: 55px;
            line-height: 55px;
            padding: 0 15px;
            font: {
                size: 14px;
                weight: 500;
                family: $optional-font-family;
            }
            ;
            .list {
                background-color: $white-color;
                box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                height: 260px;
                overflow-y: scroll;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                ;
                .option {
                    transition: $transition;
                    text-transform: capitalize;
                    font: {
                        size: $font-size;
                        family: $optional-font-family;
                        weight: 500;
                    }
                    ;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    ;
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 600;
                    }
                }
            }
            &:after {
                right: 15px;
                border-color: $black-color;
                width: 10px;
                height: 10px;
            }
        }
        .form-control {
            height: 55px;
            border: none;
            box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
            background-color: $white-color;
        }
        .default-btn {
            margin-top: 25px;
            box-shadow: 0 5px 28.5px 1.5px rgba(0, 46, 91, 0.2);
            padding: {
                top: 16px;
                bottom: 16px;
                left: 45px;
                right: 45px;
            }
            ;
        }
    }
    .image {
        position: absolute;
        left: -180px;
        bottom: -30px;
    }
}


/*================================================
Find Agent Area CSS
=================================================*/

.find-agent-area {
    background: {
        image: url(../../assets/images/assets-images/degrader2.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
}

.find-agent-area-mrh {
    background: {
        image: url(../../assets/images/assets-images/imageMrh.jpg);
        position: 0% 30%;
        size: cover;
        repeat: no-repeat;
    }
    ;
}

.find-agent-area-trc {
    background: {
        image: url(../../assets/images/assets-images/tous-avantage.jpg);
        position: 0% 30%;
        size: cover;
        repeat: no-repeat;
    }
    ;
}

.find-agent-area-voyage {
    background: {
        image: url(../../assets/images/assets-images/imageVoyage.jpg);
        position: 0% 30%;
        size: cover;
        repeat: no-repeat;
    }
    ;
}

.find-agent-area-sante {
    background: {
        image: url(../../assets/images/assets-images/imageSante.jpg);
        position: 0% 30%;
        size: cover;
        repeat: no-repeat;
    }
    ;
}

.find-agent-content {
    padding-right: 20px;
    .col-lg-12 {
        &:last-child {
            .box {
                margin-bottom: 0;
            }
        }
    }
    .box {
        position: relative;
        margin-bottom: 30px;
        background-color: $white-color;
        border-radius: 5px;
        padding: 20px 20px 20px 115px;
        .icon {
            background-color: $black-color;
            color: $white-color;
            font-size: 45px;
            position: absolute;
            left: 0;
            top: 0;
            // border-radius: 5px;
            width: 95px;
            height: 128px;
            text-align: center;
            i {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        h3 {
            margin-bottom: 10px;
            font: {
                size: 20px;
                weight: 900;
            }
            ;
        }
    }
}

.find-agent-contact-info {
    padding-left: 20px;
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        li {
            position: relative;
            padding-left: 100px;
            color: $paragraph-color;
            z-index: 1;
            margin: {
                top: 35px;
                bottom: 35px;
            }
            ;
            .icon {
                width: 85px;
                height: 85px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                background-color: #d9e5f1;
                border-radius: 50%;
                line-height: 85px;
                text-align: center;
                font-size: 35px;
                color: $white-color;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 50%;
                    z-index: -1;
                    background-color: $black-color;
                    margin: 5px;
                    content: '';
                }
            }
            span {
                color: $black-color;
                display: block;
                font-size: 16px;
                margin-bottom: 1px;
            }
            a {
                display: inline-block;
                font: {
                    family: $optional-font-family;
                    weight: 700;
                    size: 25px;
                }
                ;
            }
            &:last-child {
                margin-bottom: 0;
                &::before {
                    height: 100%;
                }
            }
            &:first-child {
                margin-top: 0;
            }
            &:nth-child(2) {
                padding-left: 0;
            }
            &::before {
                width: 1px;
                height: 280%;
                background-color: #e8e8e8;
                content: '';
                z-index: -1;
                position: absolute;
                left: 40px;
                top: 0;
            }
            cite {
                background-color: #edf6ff;
                padding: 3px 8px;
                position: relative;
                left: 25px;
                border-radius: 5px;
            }
        }
    }
}


/*================================================
Feedback Area CSS
=================================================*/

.feedback-slides {
    position: relative;
    .client-feedback {
        position: relative;
        .single-feedback {
            text-align: center;
            position: relative;
            margin-bottom: 35px;
            padding-top: 75px;
            p {
                position: relative;
                max-width: 790px;
                line-height: 1.5;
                margin: {
                    bottom: 0;
                    left: auto;
                    right: auto;
                }
                ;
                font: {
                    size: 28px;
                    weight: normal;
                    style: italic;
                }
                ;
            }
            &::before {
                position: absolute;
                left: 0;
                right: 0;
                content: "\f107";
                top: 0;
                color: $main-color;
                display: inline-block;
                margin: {
                    top: -27px;
                    left: auto;
                    right: auto;
                }
                ;
                font: {
                    size: 70px;
                    family: Flaticon;
                    style: normal;
                }
                ;
            }
        }
    }
    .client-thumbnails {
        position: relative;
        max-width: 450px;
        z-index: 1;
        margin: {
            left: auto;
            right: auto;
        }
        .item {
            .img-fill {
                cursor: pointer;
                position: relative;
                text-align: center;
                img {
                    opacity: 0.7;
                    transition: $transition;
                    display: inline-block;
                    position: relative;
                    border: 3px solid $main-color;
                    border-radius: 50%;
                    width: 85px;
                    transform: scale(.9);
                }
            }
            .title {
                opacity: 0;
                visibility: hidden;
                transition: $transition;
                margin: 20px -100px 0;
                text-align: center;
                h3 {
                    margin-bottom: 0;
                    font: {
                        size: 18px;
                        weight: 900;
                    }
                    ;
                }
                span {
                    display: block;
                    color: $main-color;
                    font-size: 14px;
                    margin-top: 5px;
                }
            }
            &.slick-center {
                .title {
                    opacity: 1;
                    visibility: visible;
                }
                .img-fill {
                    img {
                        border-color: $black-color;
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }
    .next-arrow,
    .prev-arrow {
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        color: #bcbcbc;
        z-index: 1;
        border-radius: 50%;
        outline: 0 !important;
        transition: $transition;
        opacity: 0;
        font-size: 25px;
        visibility: hidden;
        &:hover {
            color: $main-color;
        }
    }
    .next-arrow {
        right: -20px;
    }
    .prev-arrow {
        left: -20px;
    }
    &:hover {
        .next-arrow,
        .prev-arrow {
            opacity: 1;
            visibility: visible;
        }
    }
    .slick-list {
        padding: {
            left: 0 !important;
            right: 0 !important;
        }
    }
}

.single-feedback-item {
    position: relative;
    margin: {
        left: 25px;
        top: 25px;
        bottom: 8px;
    }
    ;
    .feedback-desc {
        background-color: $black-color;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        padding: {
            left: 30px;
            bottom: 30px;
            right: 30px;
            top: 35px;
        }
        ;
        p {
            margin-bottom: 0;
            position: relative;
            color: $white-color;
            font: {
                size: 16px;
                style: italic;
            }
            ;
            &::before {
                content: '"';
                position: relative;
                display: inline-block;
                left: 0;
                color: $white-color;
            }
            &::after {
                content: '"';
                position: relative;
                display: inline-block;
                right: 0;
                color: $white-color;
            }
        }
        &::before {
            content: '';
            position: absolute;
            left: 35px;
            bottom: -12px;
            width: 25px;
            height: 25px;
            z-index: -1;
            background-color: $black-color;
            transform: rotate(45deg);
        }
    }
    &::before {
        transition: $transition;
        content: "\f107";
        position: absolute;
        left: 0;
        top: -25px;
        left: -25px;
        color: $white-color;
        background-color: $main-color;
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        z-index: 2;
        text-align: center;
        font: {
            family: Flaticon;
            weight: normal;
            style: normal;
            size: 30px;
        }
        ;
    }
    .client-info {
        position: relative;
        padding-left: 75px;
        margin: {
            top: 35px;
            left: 17px;
        }
        ;
        img {
            width: 60px !important;
            height: 60px !important;
            display: inline-block !important;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: $transition;
        }
        h3 {
            margin-bottom: 0;
            color: black;
            text-transform: uppercase;
            font: {
                size: 14px;
                weight: 900;
            }
            ;
        }
        span {
            display: block;
            color: $main-color;
            font-size: 14px;
            margin-top: 5px;
        }
    }
}


/*================================================
Our Mission Area CSS
=================================================*/

.our-mission-area {
    background-color: $black-color;
}

.mission-image {
    width: 100%;
    height: 100%;
    background: {
        position: top center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    &.bg-1 {
        background: {
            image: url(../../assets/img/mission-img1.jpg);
        }
        ;
    }
    &.bg-2 {
        background: {
            image: url(../../assets/img/mission-img2.jpg);
        }
        ;
    }
    img {
        display: none;
    }
}

.mission-text {
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding: {
        top: 70px;
        bottom: 70px;
        left: 30px;
        right: 30px;
    }
    ;
    .icon {
        color: $main-color;
        font-size: 60px;
        margin: {
            bottom: 12px;
            top: -18px;
        }
        ;
    }
    h3 {
        color: $white-color;
        margin-bottom: 17px;
        position: relative;
        padding-bottom: 10px;
        font: {
            size: 22px;
            weight: 900;
        }
        ;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            width: 60px;
            margin: 0 auto;
            bottom: 0;
            background-color: $main-color;
            height: 1px;
        }
    }
    p {
        color: $white-color;
        margin-bottom: 0;
    }
    .default-btn {
        margin-top: 22px;
        background-color: $main-color;
        span {
            background-color: $white-color;
        }
        &:hover,
        &:focus {
            color: $main-color;
        }
    }
    .shape {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        margin: 0 auto;
        z-index: -1;
    }
}


/*================================================
Team Area CSS
=================================================*/

.single-team-box {
    margin-bottom: 30px;
    text-align: center;
    border-radius: 5px;
    transition: $transition;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.07);
    .image {
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        img {
            border-radius: 5px;
            transition: $transition;
        }
        .social {
            margin-bottom: 0;
            z-index: 1;
            list-style-type: none;
            position: absolute;
            left: 50%;
            bottom: -100%;
            transform: translateX(-50%);
            overflow: hidden;
            background-color: $black-color;
            border-radius: 5px;
            transition: $transition;
            padding: {
                left: 10px;
                right: 10px;
                top: 7px;
                bottom: 7px;
            }
            ;
            li {
                display: inline-block;
                margin: {
                    left: 7px;
                    right: 7px;
                }
                ;
                a {
                    display: inline-block;
                    color: $white-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .content {
        padding: 25px;
        h3 {
            margin-bottom: 0;
            font: {
                size: 21px;
                weight: 900;
            }
            ;
        }
        span {
            display: block;
            margin-top: 6px;
            color: $main-color;
            font: {
                size: $font-size;
                weight: 300;
            }
            ;
        }
    }
    &:hover {
        .image {
            img {
                transform: scale(1.2) rotate(4deg);
            }
            .social {
                bottom: 15px;
            }
        }
    }
}

.team-slides {
    &.owl-theme {
        .owl-item {
            .single-team-box {
                box-shadow: unset;
                margin-bottom: 40px;
            }
            &.active {
                &.center {
                    .single-team-box {
                        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.07);
                        .social {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 0;
                            bottom: 15px;
                        }
                    }
                }
            }
        }
        .owl-nav {
            opacity: 0;
            visibility: hidden;
            margin-top: 0;
            transition: $transition;
            [class*=owl-] {
                position: absolute;
                left: -30px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                background-color: transparent;
                transition: $transition;
                color: #bcbcbc;
                font-size: 25px;
                &.owl-next {
                    left: auto;
                    right: -30px;
                }
                &:hover {
                    color: $main-color;
                    background-color: transparent;
                }
            }
        }
        .owl-dots {
            line-height: .01;
            margin: {
                top: -5px;
                bottom: 30px;
            }
            ;
            .owl-dot {
                span {
                    width: 15px;
                    height: 2px;
                    margin: 0 3px;
                    background-color: $black-color;
                    transition: $transition;
                    border-radius: 0;
                }
                &:hover {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background-color: $main-color;
                        width: 25px;
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}


/*================================================
Events Area CSS
=================================================*/

.single-events-box {
    margin-bottom: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    background-color: $white-color;
    border-radius: 5px;
    .events-box {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        border-radius: 5px;
        .events-image {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            border-radius: 5px 0 0 5px;
            .image {
                height: 100%;
                width: 100%;
                border-radius: 5px 0 0 5px;
                background: {
                    position: center center;
                    size: cover;
                    repeat: no-repeat;
                }
                ;
                img {
                    display: none;
                }
                &.bg1 {
                    background: {
                        image: url(../../assets/img/events-image/events-img1.jpg);
                    }
                    ;
                }
                &.bg2 {
                    background: {
                        image: url(../../assets/img/events-image/events-img2.jpg);
                    }
                    ;
                }
                &.bg3 {
                    background: {
                        image: url(../../assets/img/events-image/events-img3.jpg);
                    }
                    ;
                }
                &.bg4 {
                    background: {
                        image: url(../../assets/img/events-image/events-img4.jpg);
                    }
                    ;
                }
            }
        }
        .events-content {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            .content {
                padding: 30px;
                h3 {
                    margin-bottom: 10px;
                    font: {
                        size: 22px;
                        weight: 900;
                    }
                    ;
                    a {
                        display: inline-block;
                    }
                }
                .location {
                    display: block;
                    color: $paragraph-color;
                    margin-top: 15px;
                    i {
                        color: $main-color;
                        margin-right: 2px;
                    }
                }
                .join-now-btn {
                    display: inline-block;
                    margin-top: 20px;
                    font-weight: 700;
                    position: relative;
                    padding-bottom: 3px;
                    &::before {
                        width: 100%;
                        height: 1px;
                        background: $main-color;
                        content: '';
                        position: absolute;
                        transition: $transition;
                        left: 0;
                        bottom: 0;
                    }
                    &:hover {
                        &::before {
                            width: 0;
                        }
                    }
                }
            }
        }
        .events-date {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            border-radius: 0 5px 5px 0;
            .date {
                width: 100%;
                height: 100%;
                background-color: $main-color;
                text-align: center;
                color: $white-color;
                position: relative;
                border-radius: 0 5px 5px 0;
                z-index: 1;
                font: {
                    size: 23px;
                    weight: 600;
                    family: $optional-font-family
                }
                ;
                span {
                    display: inline-block;
                    position: relative;
                    z-index: 1;
                    letter-spacing: 1px;
                    margin-bottom: 6px;
                    &::before {
                        content: '';
                        position: absolute;
                        right: 5px;
                        top: 4px;
                        background: $white-color;
                        width: 1px;
                        height: 30px;
                        transform: rotate(40deg);
                    }
                }
                h3 {
                    color: $white-color;
                    margin-bottom: 6px;
                    font: {
                        size: 25px;
                        weight: 900;
                    }
                    ;
                }
                p {
                    color: $white-color;
                    line-height: initial;
                    margin-bottom: 0;
                    font: {
                        size: 17px;
                        weight: 600;
                    }
                    ;
                }
                i {
                    display: inline-block;
                    font-size: 110px;
                    color: $white-color;
                    position: absolute;
                    z-index: -1;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: .10;
                }
            }
        }
    }
}


/*================================================
Events Details Area CSS
=================================================*/

.events-details-header {
    margin-bottom: 25px;
    .back-all-events {
        display: inline-block;
        color: $paragraph-color;
        text-transform: uppercase;
        margin-bottom: 13px;
        font: {
            weight: 600;
            size: 14px;
        }
        ;
        &:hover {
            color: $main-color;
        }
    }
    h3 {
        margin-bottom: 13px;
        font: {
            size: 24px;
            weight: 900;
        }
        ;
    }
    .events-info-meta {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        li {
            display: inline-block;
            margin-right: 14px;
            color: $paragraph-color;
            i {
                color: $main-color;
                margin-right: 1px;
            }
        }
    }
}

.events-details {
    .events-details-image {
        margin-bottom: 20px;
    }
    .events-info-links {
        margin-top: 25px;
        a {
            display: inline-block;
            background-color: $main-color;
            color: $white-color;
            border-radius: 5px;
            padding: 5px 15px 6px;
            margin-right: 9px;
        }
    }
}


/*================================================
Achievements Area CSS
=================================================*/

.achievements-area {
    .container-fluid {
        padding: 0;
        .row {
            margin: 0;
            .col-lg-6 {
                padding: 0;
            }
            .row {
                margin: {
                    left: -15px;
                    right: -15px;
                }
                ;
            }
        }
    }
}

.achievements-content {
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: $black-color;
    text-align: center;
    padding: {
        top: 100px;
        bottom: 100px;
        left: 30px;
        right: 30px;
    }
    ;
    .title {
        margin-bottom: 30px;
        .sub-title {
            color: $main-color;
            font-size: 17px;
            display: block;
            margin-bottom: 10px;
        }
        h2 {
            color: $white-color;
            margin-bottom: 0;
            font: {
                size: 42px;
                weight: 900;
            }
            ;
        }
        p {
            color: $white-color;
            font-size: 16px;
            max-width: 655px;
            margin: {
                bottom: 0;
                top: 10px;
                left: auto;
                right: auto;
            }
            ;
        }
    }
    .bg-dot {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

.single-funfact {
    text-align: center;
    margin-top: 30px;
    i {
        color: $main-color;
        font-size: 50px;
        display: inline-block;
        margin: {
            top: -11px;
            bottom: 0;
        }
        ;
    }
    h3 {
        color: $white-color;
        margin-bottom: 0;
        font: {
            size: 45px;
            weight: 900;
        }
        ;
        .sign-icon {
            font-size: 40px;
            position: relative;
            top: 4px;
        }
    }
    p {
        line-height: initial;
        color: $white-color;
        font-size: 17px;
        margin: {
            top: 3px;
            bottom: 0;
        }
        ;
    }
}

.single-achievements-image {
    width: 100%;
    height: 100%;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    &.bg1 {
        background: {
            image: url(../../assets/images/assets-images/enete40.jpg);
        }
        ;
    }
    &.bg2 {
        background: {
            image: url(../../assets/images/assets-images/entete31.jpg);
        }
        ;
    }
    &.bg3 {
        background: {
            image: url(../../assets/images/assets-images/entete42.jpg);
        }
        ;
    }
    img {
        width: auto !important;
        display: none !important;
    }
}

.single-achievements-image {
    width: 100%;
    height: 100%;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    &.bg1mrh {
        background: {
            image: url(../../assets/images/assets-images/entete71.jpg);
        }
        ;
    }
    &.bg2mrh {
        background: {
            image: url(../../assets/images/assets-images/entete72.jpg);
        }
        ;
    }
    &.bg3mrh {
        background: {
            image: url(../../assets/images/assets-images/entete73.jpg);
        }
        ;
    }
    img {
        width: auto !important;
        display: none !important;
    }
}

.single-achievements-image {
    width: 100%;
    height: 100%;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    &.bg1voyage {
        background: {
            image: url(../../assets/images/assets-images/entete76.jpg);
        }
        ;
    }
    &.bg2voyage {
        background: {
            image: url(../../assets/images/assets-images/entete77.jpg);
        }
        ;
    }
    &.bg3voyage {
        background: {
            image: url(../../assets/images/assets-images/entete78.jpg);
        }
        ;
    }
    img {
        width: auto !important;
        display: none !important;
    }
}

.single-achievements-image {
    width: 100%;
    height: 100%;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    &.bg1sante {
        background: {
            image: url(../../assets/images/assets-images/entete82.jpg);
        }
        ;
    }
    &.bg2sante {
        background: {
            image: url(../../assets/images/assets-images/entete83.jpg);
        }
        ;
    }
    &.bg3sante {
        background: {
            image: url(../../assets/images/assets-images/entete84.jpg);
        }
        ;
    }
    img {
        width: auto !important;
        display: none !important;
    }
}

.achievements-image-slides {
    height: 100%;
    .owl-stage-outer {
        width: 100%;
        height: 100%;
        .owl-stage {
            height: 100%;
            .owl-item {
                height: 100%;
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            opacity: 0;
            visibility: hidden;
            margin-top: 0;
            transition: $transition;
            [class*=owl-] {
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                background-color: transparent;
                transition: $transition;
                color: $white-color;
                font-size: 35px;
                &.owl-next {
                    left: auto;
                    right: 25px;
                }
                &:hover {
                    color: $main-color;
                    background-color: transparent;
                }
            }
        }
        .owl-dots {
            line-height: .01;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 25px;
            margin: {
                top: 0;
            }
            ;
            .owl-dot {
                span {
                    width: 15px;
                    height: 2px;
                    margin: 0 3px;
                    background-color: $white-color;
                    transition: $transition;
                    border-radius: 0;
                }
                &:hover {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background-color: $main-color;
                        width: 25px;
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.divider {
    position: absolute;
    z-index: 1;
    top: 0;
    height: 100%;
    width: 5vw;
    max-width: 72px;
    left: -1px;
    pointer-events: none;
    -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff' fill-rule='evenodd' transform='translate(50.000000, 50.000000) rotate(-90.000000) translate(-50.000000, -50.000000)' /%3e%3c/svg%3e");
    mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff' fill-rule='evenodd' transform='translate(50.000000, 50.000000) rotate(-90.000000) translate(-50.000000, -50.000000)' /%3e%3c/svg%3e");
    -webkit-mask-size: 100% 101%;
    mask-size: 100% 101%;
    background-color: $black-color;
    transform: scaleX(-1);
    z-index: 2;
}


/*================================================
Blog Area CSS
=================================================*/

.single-blog-post {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    border-radius: 5px;
    .post-image {
        position: relative;
        overflow: hidden;
        a {
            display: block;
            img {
                border-radius: 5px;
                transition: $transition;
            }
        }
        .date {
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $white-color;
            border-radius: 0 5px 0 0;
            padding: 10px 25px 0;
            z-index: 1;
            font: {
                size: 14px;
                weight: 300;
            }
            ;
            i {
                color: $main-color;
                margin-right: 2px;
            }
        }
    }
    .post-content {
        padding: 25px;
        h3 {
            margin-bottom: 10px;
            line-height: 1.4;
            font: {
                size: 22px;
                weight: 900;
            }
            ;
        }
        p {
            margin-bottom: 0;
        }
        .default-btn {
            margin-top: 20px;
        }
    }
    &:hover {
        .post-image {
            a {
                img {
                    transform: scale(1.3) rotate(4deg);
                }
            }
        }
    }
}

.blog-notes {
    margin-top: 10px;
    text-align: center;
    p {
        line-height: initial;
        a {
            display: inline-block;
            color: $main-color;
            &:hover {
                color: $black-color;
            }
        }
    }
}


/*================================================
Blog Details Area CSS
=================================================*/

.blog-details-desc {
    .article-content {
        margin-top: 30px;
        .entry-meta {
            margin-bottom: -8px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
                li {
                    position: relative;
                    display: inline-block;
                    color: $black-color;
                    margin-right: 25px;
                    a {
                        display: inline-block;
                        color: $paragraph-color;
                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        margin-right: 5px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 12px;
                        right: -15px;
                        width: 6px;
                        height: 1px;
                        background: $main-color;
                    }
                    &:last-child {
                        margin-right: 0;
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 15px;
                top: 25px;
            }
            font: {
                size: 22px;
                weight: 900;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            }
            li {
                margin-bottom: 16px;
                position: relative;
                padding-left: 34px;
                color: $paragraph-color;
                i {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
                    background-color: #faf5f5;
                    color: $main-color;
                    transition: $transition;
                    display: inline-block;
                    font-size: 11px;
                    position: absolute;
                    left: 0;
                    top: -2px;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        flex-wrap: wrap;
        margin: {
            top: 30px;
        }
        ;
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: $paragraph-color;
            span {
                display: inline-block;
                color: $black-color;
                font-size: 20px;
                margin-right: 7px;
                position: relative;
                top: 2px;
            }
            a {
                display: inline-block;
                color: $paragraph-color;
                &:hover {
                    color: $main-color;
                }
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;
            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin: {
                    bottom: 0;
                }
                ;
                li {
                    display: inline-block;
                    span {
                        display: inline-block;
                        margin-right: 5px;
                        font-weight: 500;
                        color: $paragraph-color;
                    }
                    a {
                        display: block;
                        color: $main-color;
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        border-radius: 50%;
                        background-color: #eeeef0;
                        text-align: center;
                        font-size: 14px;
                        margin-left: 5px;
                        &:hover {
                            color: $white-color;
                            background-color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
}

.comments-area {
    padding: 25px;
    margin-top: 30px;
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
    background-color: $white-color;
    .comments-title {
        position: relative;
        margin: {
            bottom: 30px;
        }
        line-height: initial;
        font: {
            size: 22px;
            weight: 900;
        }
        ;
        &::before {
            content: '';
            height: 25px;
            width: 3px;
            left: -25px;
            position: absolute;
            background: $main-color;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    ol,
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px solid #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        .reply {
            margin-top: 15px;
            a {
                border: 1px solid #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 6px 20px;
                border-radius: 30px;
                text-transform: uppercase;
                position: relative;
                z-index: 1;
                font: {
                    size: 13px;
                    weight: 600;
                }
                ;
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
        p {
            font-size: 14px;
        }
    }
    .comment-author {
        font-size: 17px;
        margin-bottom: 0.2em;
        position: relative;
        z-index: 2;
        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 600;
                family: $optional-font-family;
            }
            ;
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: .8em;
        color: $paragraph-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        }
        a {
            color: $paragraph-color;
            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;
        .comment-reply-title {
            margin-bottom: 0;
            position: relative;
            font: {
                size: 22px;
                weight: 900;
            }
            ;
            &::before {
                content: '';
                height: 25px;
                width: 3px;
                left: -25px;
                position: absolute;
                background: $main-color;
                top: 50%;
                transform: translateY(-50%);
            }
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 0;
                top: 10px;
            }
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            margin-top: 15px;
            float: left;
            width: 100%;
        }
        label {
            display: block;
            font-weight: 600;
            color: $black-color;
            margin-bottom: 5px;
        }
        input[type="date"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="week"],
        input[type="month"],
        input[type="text"],
        input[type="email"],
        input[type="url"],
        input[type="password"],
        input[type="search"],
        input[type="tel"],
        input[type="number"],
        textarea {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: $transition;
            &:focus {
                border-color: $main-color;
            }
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;
            input {
                position: absolute;
                left: 0;
                top: 6px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
                font-weight: normal;
                position: relative;
                top: -2px;
            }
        }
        .form-submit {
            float: left;
            width: 100%;
            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 12px 35px 10px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                transition: $transition;
                border-radius: 5px;
                font: {
                    weight: 700;
                    size: $font-size;
                    family: $optional-font-family;
                }
                ;
                &:hover,
                &:focus {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
}


/*================================================
Case Study Area CSS
=================================================*/

.single-case-study-box {
    transition: $transition;
    background-color: $white-color;
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
    .case-study-image {
        visibility: hidden;
        background: {
            size: cover;
            position: center center;
            repeat: no-repeat;
        }
        ;
        width: 100%;
        height: 235px;
        border: {
            top-left-radius: 5px;
            top-right-radius: 5px;
        }
        ;
        &.bg1 {
            background-image: url(../../assets/img/case-study-image/img1.jpg);
        }
        &.bg2 {
            background-image: url(../../assets/img/case-study-image/img2.jpg);
        }
        &.bg3 {
            background-image: url(../../assets/img/case-study-image/img3.jpg);
        }
        &.bg4 {
            background-image: url(../../assets/img/case-study-image/img4.jpg);
        }
        &.bg5 {
            background-image: url(../../assets/img/case-study-image/img5.jpg);
        }
        &.bg6 {
            background-image: url(../../assets/img/case-study-image/img6.jpg);
        }
    }
    .case-study-img-hover {
        transition: .5s;
        background: {
            size: cover;
            position: center center;
            repeat: no-repeat;
        }
        ;
        width: 100%;
        position: absolute;
        height: 235px;
        top: 0;
        left: 0;
        border: {
            top-left-radius: 5px;
            top-right-radius: 5px;
        }
        ;
        &.bg1 {
            background-image: url(../../assets/img/case-study-image/img1.jpg);
        }
        &.bg2 {
            background-image: url(../../assets/img/case-study-image/img2.jpg);
        }
        &.bg3 {
            background-image: url(../../assets/img/case-study-image/img3.jpg);
        }
        &.bg4 {
            background-image: url(../../assets/img/case-study-image/img4.jpg);
        }
        &.bg5 {
            background-image: url(../../assets/img/case-study-image/img5.jpg);
        }
        &.bg6 {
            background-image: url(../../assets/img/case-study-image/img6.jpg);
        }
    }
    .case-study-info {
        z-index: 2;
        background-color: $white-color;
        padding: 25px;
        border: {
            bottom-left-radius: 5px;
            bottom-right-radius: 5px;
        }
        ;
        .category {
            display: block;
            color: $main-color;
            margin-bottom: 10px;
            font: {
                size: $font-size;
                weight: 500;
            }
            ;
        }
        .title {
            line-height: 1.3;
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 900;
            }
            ;
            a {
                display: inline-block;
                color: $black-color;
            }
        }
    }
    &:hover {
        box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
        transform: scale(1.10, 1.10);
        .case-study-img-hover {
            height: 100%;
            opacity: 0.2;
        }
        .case-study-info {
            background-color: transparent;
            position: relative;
        }
    }
}


/*================================================
Case Study Details Area CSS
=================================================*/

.case-study-details-image {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    img {
        transition: $transition;
    }
    a {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 75px;
        height: 75px;
        line-height: 75px;
        font-size: 30px;
        background-color: $main-color;
        border-radius: 50%;
        text-align: center;
        color: $white-color;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
        margin-top: 20px;
        &:hover {
            background-color: $black-color;
            color: $white-color;
        }
    }
    &:hover {
        a {
            margin-top: 0;
            opacity: 1;
            visibility: visible;
        }
        img {
            transform: scale(1.3) rotate(4deg);
        }
    }
}

.case-study-details-desc {
    margin-top: 5px;
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
            weight: 900;
        }
        ;
    }
    .features-text {
        margin: {
            top: 25px;
            bottom: 25px;
        }
        h4 {
            margin-bottom: 10px;
            font: {
                size: 17px;
                weight: 600;
            }
            ;
            i {
                font-size: 16px;
                margin-right: 4px;
                color: $main-color;
            }
        }
    }
    .case-study-details-info {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            right: -15px;
            left: -15px;
            top: 35px;
        }
        ;
        .single-info-box {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
            padding: {
                left: 15px;
                right: 15px;
            }
            h4 {
                margin-bottom: 10px;
                font: {
                    size: 19px;
                    weight: 700;
                }
            }
            span {
                display: block;
                color: $paragraph-color;
                font-size: 15px;
            }
            .social {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
                li {
                    display: inline-block;
                    margin-right: 12px;
                    a {
                        color: $paragraph-color;
                        display: inline-block;
                        &:hover {
                            color: $main-color;
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
    }
}


/*================================================
Page Title Area CSS
=================================================*/

.page-title-area {
    height: 500px;
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
}

.page-title-bg1 {
    background-image: url(../../assets/img/page-title-image/page-title1.jpg);
}

.page-title-bg2 {
    background-image: url(../../assets/img/page-title-image/page-title2.jpg);
}

.page-title-bg2A {
    background-image: url(../../assets/images/activationCompte.jpg);
}

.page-title-bg3 {
    background-image: url(../../assets/images/assets-images/entete87.jpg);
}

.page-title-content {
    text-align: center;
    margin-top: 170px;
    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 42px;
            weight: 900;
        }
        ;
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 12px;
            bottom: 0;
        }
        ;
        li {
            color: $white-color;
            display: inline-block;
            position: relative;
            font: {
                weight: 400;
                size: 17px;
            }
            ;
            margin: {
                left: 12px;
                right: 12px;
            }
            ;
            a {
                display: inline-block;
                color: $white-color;
            }
            &::before {
                content: '';
                position: absolute;
                right: -15px;
                top: 11px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $main-color;
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}


/*================================================
Pagination Area CSS
=================================================*/

.pagination-area {
    margin-top: 35px;
    text-align: center;
    .page-numbers {
        width: 45px;
        height: 45px;
        margin: 0 5px;
        display: inline-block;
        background-color: $white-color;
        line-height: 48px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        font: {
            size: 18px;
            family: $optional-font-family;
            weight: 700;
        }
        ;
        &.current,
        &:hover,
        &:focus {
            background: $main-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
    }
}


/*================================================
Sidebar Widget Area CSS
=================================================*/

.widget-area {
    .widget {
        margin-top: 35px;
        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 25px;
            position: relative;
            font: {
                weight: 900;
                size: 20px;
            }
            ;
            &::before {
                content: '';
                position: absolute;
                background: $main-color;
                bottom: -1px;
                left: 0;
                width: 50px;
                height: 1px;
            }
        }
    }
    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 15px;
        form {
            position: relative;
            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;
                &:focus {
                    border-color: $main-color;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                z-index: 1;
                border: none;
                color: $white-color;
                background-color: $main-color;
                transition: $transition;
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_truz_posts_thumb {
        position: relative;
        overflow: hidden;
        .item {
            overflow: hidden;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;
                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(../../assets/img/blog-image/img7.jpg);
                    }
                    &.bg2 {
                        background-image: url(../../assets/img/blog-image/img8.jpg);
                    }
                    &.bg3 {
                        background-image: url(../../assets/img/blog-image/img9.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;
                time {
                    display: block;
                    color: $paragraph-color;
                    margin: {
                        top: 3px;
                        bottom: 5px;
                    }
                    ;
                    font: {
                        size: 12px;
                    }
                    ;
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 16px;
                        weight: 700;
                    }
                    ;
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .widget_recent_entries {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 600;
                    size: $font-size;
                    family: $optional-font-family;
                }
                ;
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
                .post-date {
                    display: block;
                    font-size: 13px;
                    color: $paragraph-color;
                    margin-top: 4px;
                }
            }
        }
    }
    .widget_recent_comments {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 600;
                    size: $font-size;
                    family: $optional-font-family;
                }
                ;
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_archive {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                padding-left: 14px;
                color: $black-color;
                font: {
                    weight: 600;
                    size: $font-size;
                    family: $optional-font-family;
                }
                ;
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                font: {
                    weight: 600;
                    size: $font-size;
                    family: $optional-font-family;
                }
                ;
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: block;
                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    float: right;
                }
            }
        }
    }
    .widget_meta {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                font: {
                    weight: 600;
                    size: $font-size;
                    family: $optional-font-family;
                }
                ;
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            color: $black-color;
            font: {
                weight: 600;
                family: $optional-font-family;
                size: 14px !important;
            }
            ;
            padding: 6px 13px;
            border: 1px dashed #eeeeee;
            margin: {
                top: 8px;
                right: 4px;
            }
            ;
            &:hover,
            &:focus {
                color: $white-color;
                background-color: $main-color;
                border-color: $main-color;
            }
        }
    }
    .widget_events_details {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                border-bottom: 1px solid #eeeeee;
                color: $paragraph-color;
                padding: {
                    bottom: 10px;
                    top: 10px;
                }
                ;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
                span {
                    display: inline-block;
                    color: $black-color;
                    font: {
                        weight: 700;
                        family: $optional-font-family;
                    }
                    ;
                }
                a {
                    display: inline-block;
                    margin-left: 4px;
                    color: $paragraph-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}


/*================================================
Insurance Details Area CSS
=================================================*/

.insurance-details-header {
    .content {
        h3 {
            margin-bottom: 18px;
            font: {
                size: 26px;
                weight: 700;
            }
            ;
        }
    }
}

.insurance-details-desc {
    margin-top: 30px;
    h3 {
        margin: {
            bottom: 15px;
            top: 25px;
        }
        ;
        font: {
            size: 24px;
            weight: 700;
        }
        ;
    }
    .wp-block-gallery {
        &.columns-3 {
            padding-left: 0;
            list-style-type: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin: {
                right: -10px;
                left: -10px;
                bottom: 25px;
                top: 20px;
            }
            li {
                -ms-flex: 0 0 33.3333%;
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
                padding: {
                    right: 10px;
                    left: 10px;
                }
                figure {
                    margin-bottom: 0;
                }
            }
        }
    }
    .features-list {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 10px;
            bottom: 10px;
        }
        ;
        li {
            margin-bottom: 16px;
            position: relative;
            padding-left: 34px;
            color: $paragraph-color;
            i {
                width: 25px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
                background-color: #faf5f5;
                color: $main-color;
                transition: $transition;
                display: inline-block;
                font-size: 11px;
                position: absolute;
                left: 0;
                top: -2px;
            }
            &:hover {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    blockquote,
    .blockquote {
        text-align: left;
    }
}

blockquote,
.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    ;
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 600;
            family: $optional-font-family;
            size: 21px !important;
        }
        ;
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        content: "\f10e";
        position: absolute;
        left: 50px;
        top: -50px;
        z-index: -1;
        font: {
            family: "Font Awesome 5 Free";
            size: 140px;
            weight: 900;
        }
        ;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $main-color;
        margin: {
            top: 20px;
            bottom: 20px;
        }
        ;
    }
}

.truz-post-navigation {
    overflow: hidden;
    margin-top: 30px;
    padding: {
        top: 25px;
        bottom: 25px;
    }
    ;
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
    ;
}

.prev-link-wrapper {
    float: left;
    a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        &:hover {
            .image-prev {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .image-prev {
        display: inline-block;
        min-width: 100px;
        min-height: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-right: 20px;
        position: relative;
        transition: $transition;
        img {
            border-radius: 5px;
            width: 100px;
            height: 100px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: 16px;
                weight: 700;
            }
            ;
        }
    }
    .prev-link-info-wrapper {
        color: $black-color;
    }
    .prev-title {
        max-width: 210px;
        display: inline-block;
        font: {
            family: $optional-font-family;
            weight: 900;
            size: 17px;
        }
        ;
    }
    .meta-wrapper {
        display: block;
        color: $paragraph-color;
        text-transform: capitalize;
        margin-top: 4px;
        font: {
            weight: 400;
            size: 14px;
        }
        ;
    }
}

.next-link-wrapper {
    float: right;
    text-align: right;
    a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        &:hover {
            .image-next {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .image-next {
        display: inline-block;
        min-width: 100px;
        min-height: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-left: 20px;
        position: relative;
        transition: $transition;
        img {
            border-radius: 5px;
            width: 100px;
            height: 100px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: 16px;
                weight: 700;
            }
            ;
        }
    }
    .next-link-info-wrapper {
        color: $black-color;
    }
    .next-title {
        max-width: 210px;
        display: inline-block;
        font: {
            family: $optional-font-family;
            weight: 900;
            size: 17px;
        }
        ;
    }
    .meta-wrapper {
        display: block;
        color: $paragraph-color;
        text-transform: capitalize;
        margin-top: 4px;
        font: {
            weight: 400;
            size: 14px;
        }
        ;
    }
}


/*================================================
FAQ Area CSS
=================================================*/

.faq-image {
    width: 100%;
    height: 100%;
    background: {
        image: url(../../assets/img/faq-img1.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    ;
    img {
        display: none;
    }
}

.faq-accordion {
    padding: {
        top: 60px;
        bottom: 60px;
        left: 50px;
        right: 50px;
    }
    ;
    .sub-title {
        display: block;
        margin-bottom: 10px;
        color: $main-color;
        font: {
            size: 18px;
        }
        ;
    }
    h2 {
        margin-bottom: 0;
        line-height: 1.3;
        max-width: 610px;
        font: {
            size: 42px;
            weight: 900;
        }
        ;
    }
    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin: {
            bottom: 0;
            top: 30px;
        }
        ;
        .accordion-item {
            display: block;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            background: $white-color;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion-title {
            padding: 12px 20px 12px 51px;
            color: $black-color;
            position: relative;
            display: block;
            font: {
                size: 16px;
                weight: 600;
                family: $optional-font-family;
            }
            ;
            i {
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                text-align: center;
                height: 100%;
                background: #cfe4ed;
                color: $main-color;
                font-size: 15px;
                transition: $transition;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            }
            &.active {
                i {
                    background-color: $main-color;
                    color: $white-color;
                    &::before {
                        content: "\f068";
                    }
                }
            }
        }
        .accordion-content {
            display: none;
            position: relative;
            padding: 15px;
            font-size: $font-size;
            border-top: 1px solid #eeeeee;
            &.show {
                display: block;
            }
        }
    }
}

.faq-item {
    margin-bottom: 30px;
    h3 {
        margin-bottom: 12px;
        font: {
            size: 20px;
            weight: 900;
        }
    }
}

.faq-contact-area {
    position: relative;
    z-index: 1;
}

.faq-contact-form {
    max-width: 750px;
    margin: {
        left: auto;
        right: auto;
    }
    ;
    form {
        text-align: center;
        .form-control {
            background-color: $white-color;
            border: none;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
            height: 55px;
        }
        .form-group {
            text-align: left;
        }
        .row {
            margin: {
                left: -7px;
                right: -7px;
            }
            ;
            .col-lg-12,
            .col-lg-6 {
                padding: {
                    left: 7px;
                    right: 7px;
                }
                ;
            }
        }
        textarea.form-control {
            height: auto;
            padding-top: 15px;
        }
        .default-btn {
            font-size: 16px;
            margin-top: 10px;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.5) !important;
        }
        .help-block {
            ul {
                padding-left: 0;
                list-style-type: none;
                margin: {
                    top: 5px;
                    bottom: 0;
                }
                ;
                li {
                    color: red;
                }
            }
        }
        #msgSubmit {
            margin-bottom: 0;
            text-align: center !important;
            &.text-danger,
            &.text-success {
                margin-top: 8px;
                font: {
                    size: 20px;
                    weight: 600;
                }
                ;
            }
        }
    }
}

.bg-map {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    text-align: center;
    margin: 0 auto;
    opacity: .4;
}


/*================================================
404 Error Area CSS
=================================================*/

.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
    h3 {
        font: {
            size: 42px;
            weight: 900;
        }
        ;
        margin: {
            top: 45px;
            bottom: 15px;
        }
        ;
    }
    p {
        max-width: 520px;
        margin: 0 auto 25px;
    }
}


/*================================================
Join Area CSS
=================================================*/

.join-area {
    position: relative;
    z-index: 1;
    background-color: $white-color;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 80%;
        z-index: -1;
        background-color: $main-color;
    }
}

.join-content {
    position: relative;
    background-color: $main-color;
    max-width: 580px;
    top: 45px;
    h2 {
        color: $white-color;
        font: {
            size: 42px;
            weight: 900;
        }
        ;
    }
    p {
        color: $white-color;
        opacity: 0.95;
    }
    .default-btn {
        margin-top: 10px;
        span {
            background-color: $white-color;
        }
        &:hover,
        &:focus {
            color: $black-color;
        }
    }
}


/*================================================
Contact Area CSS
=================================================*/

.contact-area {
    position: relative;
    z-index: 1;
}

.contact-form {
    max-width: 750px;
    margin: {
        left: auto;
        right: auto;
    }
    ;
    form {
        .form-control {
            background-color: $white-color;
            border: none;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
            height: 60px;
        }
        .form-group {
            text-align: left;
        }
        .row {
            margin: {
                left: -7px;
                right: -7px;
            }
            ;
            .col-lg-12,
            .col-lg-6 {
                padding: {
                    left: 7px;
                    right: 7px;
                }
                ;
            }
        }
        textarea.form-control {
            height: auto;
            padding-top: 15px;
        }
        .default-btn {
            font-size: 16px;
            margin-top: 10px;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.5) !important;
        }
        .help-block {
            ul {
                padding-left: 0;
                list-style-type: none;
                margin: {
                    top: 5px;
                    bottom: 0;
                }
                ;
                li {
                    color: red;
                }
            }
        }
        #msgSubmit {
            margin-bottom: 0;
            text-align: center !important;
            &.text-danger,
            &.text-success {
                margin-top: 8px;
                font: {
                    size: 20px;
                    weight: 600;
                }
                ;
            }
        }
    }
}

#map {
    width: 100%;
    height: 100%;
    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}

.contact-info {
    text-align: center;
    margin-top: 100px;
    .contact-info-content {
        h3 {
            margin-bottom: 15px;
            font: {
                size: 20px;
                weight: 900;
            }
            ;
        }
        h2 {
            margin-bottom: 0;
            font: {
                size: 42px;
                weight: 700;
            }
            ;
            a {
                display: inline-block;
                color: $main-color;
                &:hover {
                    color: $black-color;
                }
                &:not(:first-child) {
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
            span {
                display: block;
                color: $paragraph-color;
                margin: {
                    top: 5px;
                    bottom: 1px;
                }
                ;
                font: {
                    size: 16px;
                    weight: 500;
                }
                ;
            }
        }
        .social {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 0;
                top: 20px;
            }
            li {
                display: inline-block;
                margin: 0 4px;
                a {
                    width: 38px;
                    height: 38px;
                    line-height: 35px;
                    border: 1px solid #dadada;
                    border-radius: 50%;
                    display: inline-block;
                    color: #aba5a5;
                    text-align: center;
                    i {
                        font-size: 14px;
                    }
                    &:hover {
                        color: $white-color;
                        border-color: $main-color;
                        background-color: $main-color;
                        transform: translateY(-2px);
                    }
                }
            }
        }
    }
}


/*================================================
Subscribe Area CSS
=================================================*/

.subscribe-area {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--ColorDefault);
}

.subscribe-content {
    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 32px;
            weight: 900;
        }
        ;
    }
    p {
        color: $white-color;
        opacity: 0.9;
        margin: {
            top: 10px;
            bottom: 0;
        }
        ;
    }
}

.subscribe-form {
    padding-left: 30px;
    form {
        position: relative;
        .input-newsletter {
            display: block;
            width: 100%;
            color: $black-color;
            height: 65px;
            border-radius: 5px;
            border: none;
            padding-left: 15px;
            outline: 0;
            box-shadow: unset !important;
            font: {
                size: 16px;
                weight: 500;
                family: $main-font-family;
            }
            ;
            &::placeholder {
                color: #99afc3;
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            border-radius: 5px;
            height: 55px;
            line-height: 55px;
            padding: 0 30px;
            transition: $transition;
            font: {
                size: $font-size;
                family: $main-font-family;
                weight: 700;
            }
            ;
            &:hover {
                color: $white-color;
                background-color: #000000;
            }
        }
    }
    #validator-newsletter {
        color: $white-color;
        position: absolute;
        left: 0;
        bottom: -32px;
    }
}


/*================================================
Footer Area CSS
=================================================*/

.footer-area {
    // background-color: $black-color;
    background-color: #1d1b1b;
    padding-top: 70px;
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: {
            image: url(../../assets/img/bg-line.png);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
        ;
    }
}

.single-footer-widget {
    margin-bottom: 30px;
    h3 {
        margin-bottom: 30px;
        color: $white-color;
        position: relative;
        padding-bottom: 8px;
        // border-bottom: 1px solid #0c4776;
        font: {
            size: 22px;
            weight: 900;
        }
        &::before {
            bottom: -1px;
            left: 0;
            width: 55px;
            height: 1px;
            background-color: $main-color;
            content: '';
            position: absolute;
        }
    }
    .logo {
        a {
            display: inline-block;
        }
        p {
            color: $white-color;
            margin: {
                bottom: 0;
                top: 15px;
            }
            ;
        }
    }
    .social {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 20px;
        }
        ;
        li {
            display: inline-block;
            margin-right: 6px;
            a {
                display: inline-block;
                background-color: $main-color;
                color: $black-color;
                width: 38px;
                font-size: 17px;
                height: 38px;
                line-height: 39px;
                border-radius: 50%;
                text-align: center;
                i {
                    &.flaticon-facebook {
                        display: inline-block;
                        padding-left: 4px;
                    }
                    &.flaticon-twitter {
                        display: inline-block;
                        position: relative;
                        top: 2px;
                    }
                }
                &:hover {
                    background-color: $white-color;
                    color: $black-color;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .footer-quick-links {
        display: flex;
        padding-left: 0;
        flex-wrap: wrap;
        list-style-type: none;
        margin: {
            right: calc(var(--bs-gutter-x) / -2);
            left: calc(var(--bs-gutter-x) / -2);
            bottom: 0;
            top: -12px;
        }
        ;
        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: {
                left: calc(var(--bs-gutter-x) / 2);
                right: calc(var(--bs-gutter-x) / 2);
                top: 12px;
            }
            ;
            a {
                display: inline-block;
                color: $white-color;
                font-size: $font-size;
                text-decoration: none !important;
                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    .footer-contact-info {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
        }
        ;
        li {
            color: $white-color;
            font-size: $font-size;
            margin-bottom: 12px;
            span {
                display: inline-block;
                font-weight: 600;
                color: $white-color;
                margin-right: 2px;
            }
            a {
                display: inline-block;
                color: $white-color;
                position: relative;
                text-decoration: none !important;
                &::before {
                    width: 100%;
                    height: 1px;
                    background-color: $main-color;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;
                }
                &:hover {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
                a {
                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.copyright-area {
    margin-top: 40px;
    border-top: 1px solid var(--ColorDefault);
    padding: {
        top: 25px;
        bottom: 25px;
    }
    ;
    p {
        color: #ebe7e7;
        font-size: 15px;
        a {
            color: $white-color;
            display: inline-block;
            font-weight: 600;
            &:hover {
                color: $main-color;
            }
        }
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        text-align: right;
        li {
            display: inline-block;
            color: #ebe7e7;
            font-size: 15px;
            position: relative;
            margin: {
                left: 12px;
                right: 12px;
            }
            ;
            a {
                display: inline-block;
                color: #ebe7e7;
                text-decoration: none !important;
                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                content: '';
                position: absolute;
                top: 5px;
                right: -13px;
                width: 1px;
                height: 14px;
                background-color: $white-color;
            }
            &:last-child {
                margin-right: 0;
                &::before {
                    display: none;
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}


/*================================================
Go Top CSS
=================================================*/

.go-top {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 15px;
    color: $white-color;
    background-color: $black-color;
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 42px;
    line-height: 42px;
    opacity: 0;
    visibility: hidden;
    transition: .9s;
    &.active {
        top: 98%;
        transform: translateY(-98%);
        opacity: 1;
        visibility: visible;
    }
    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;
        &:last-child {
            opacity: 0;
            visibility: hidden;
            top: 60%;
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $main-color;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
    }
    &:hover,
    &:focus {
        color: $white-color;
        &::before {
            opacity: 1;
            visibility: visible;
        }
        i {
            &:first-child {
                opacity: 0;
                top: 0;
                visibility: hidden;
            }
            &:last-child {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}

.horizontal.large-empty ul.steps-indicator {
    background: hsl(212deg 28% 36%) !important;
}

.btnO {
    color: var(--ColorDefault) !important;
    text-transform: uppercase !important;
}

.btnO:hover {
    color: white !important;
}

// .css-14dz7n .wrapper {
//     color: white;
//     width: auto;
//     background-image: linear-gradient(80deg, rgb(255 127 43), rgb(255 127 43)) !important;
// }
.muiBox-root {
    .wrapper {
        color: white !important;
        width: auto !important;
        background-image: linear-gradient(80deg, var(--ColorDefault), var(--ColorDefault)) !important;
    }
}

.height_image {
    height: 750px !important;
}

.color_title {
    color: black;
}

.swal2-styled.swal2-confirm {
    background-color: var(--ColorDefault) !important;
}