@import url(https://fonts.googleapis.com/css?family=Capriola&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);

@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
// @import "~bootstrap/scss/bootstrap";
// @import '~swiper/dist/css/swiper.min.css';
// @import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
// @import "~animate-scss/animate.css";
// @import "color-1";
// @import "font-awesome.scss";
// @import "themify.scss";
// @import "flaticon.scss";
// @import "../css/keyframes.css";
// @import "~animate.css/animate.min.css";
 @import '~ngx-toastr/toastr';
@import '~angular-archwizard/archwizard.css';
